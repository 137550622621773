import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalService } from "../../../services/global.service";
import { PatientPostService } from "../../../services/patient-post.service";
import { PatientPostReplyService } from "../../../services/patient-post-reply.service";
import { service_url } from "../../../services/service-url";
import swal from 'sweetalert2'
import { from } from 'rxjs';
import { DatePipe } from '@angular/common';
import { PatientService } from 'src/app/services/patient.service';
import { element } from '@angular/core/src/render3';

@Component({
  selector: 'app-patient-posts',
  templateUrl: './patient-posts.component.html',
  styleUrls: ['./patient-posts.component.scss']
})
export class PatientPostsComponent implements OnInit {
  model: any = {}
  showBtnSend: string = 'none';
  commentHeight: string = '40.19px';
  postForm: FormGroup;
  submitted = false;
  selectedFile: File = null;
  selectedCommentFile: File = null;
  imageUrl: string = './assets/images/placeholder-600x400.png';
  imgCommentURL: any;
  patientPosts: any;
  api_url: any;
  imgUser: any;
  patient_id: any = sessionStorage.getItem('patient_id')
  user: any
  showComment: boolean = false
  post_id: any;
  carer_patients: any;
  doctor_patients: any;
  new_post_users_null: boolean = true;
  doctor_patients_tag: any = [];
  carer_patients_tag: any = [];
  constructor(
    private patientService: PatientService,
    private formBuilder: FormBuilder,
    private global: GlobalService,
    private patientPostService: PatientPostService,
    private patientPostReplyService: PatientPostReplyService
  ) {
    this.api_url = service_url.api_url
    var sessions = JSON.parse(sessionStorage.getItem('sessions'))
    this.user = sessions.user
  }

  ngOnInit() {
    this.setImage()
    this.setForm()
    this.getPatientPosts()
  }
  setImage() {
    this.imgUser = "../../../../assets/images/user/user-male.png"
    if (this.user.doctor) {
      if (this.user.doctor.image_url.length > 0)
        this.imgUser = this.api_url + this.user.doctor.image_url
    } else if (this.user.carer) {
      if (this.user.carer.image_url.length > 0)
        this.imgUser = this.api_url + this.user.carer.image_url
    }
  }

  commentFocus() {
    this.showBtnSend = 'block'
    this.commentHeight = '100px'

  }
  commentFocusOut() {
    setTimeout(() => {
      this.showBtnSend = 'none'
      this.commentHeight = '40.19px'
    }, 300);
  }

  setForm() {
    this.postForm = this.formBuilder.group({
      title: ['', Validators.required],
      content: ['', Validators.required],
      carer_patients: [null, Validators.required],
      doctor_patients: [null, Validators.required],
      // image: null,
    });
  }

  showForm(post = '') {
    this.getCarerPatients()
    this.getDoctorPatients()
    this.submitted = false;
    this.new_post_users_null = false

    if (post != '') {
      this.postForm = this.formBuilder.group({
        title: [post['title'], Validators.required],
        content: [post['content'], Validators.required],
        carer_patients: [post['carer_patients']],
        doctor_patients: [post['doctor_patients']],
        id: post['id']
      });
      this.imageUrl = (post['image_url'] != null ? post['image_url'] : './assets/images/placeholder-600x400.png')

      this.carer_patients_tag = post['carer_patients']
      this.doctor_patients_tag = post['doctor_patients']
    }
    else {
      this.setForm()
      if (this.user.role_id == 2) {
        this.user.doctor.name = this.user.doctor.display_name
        this.postForm.controls.doctor_patients.patchValue([this.user.doctor])
      }
      this.selectedFile = null;
      this.imageUrl = './assets/images/placeholder-600x400.png';
    }
  }

  handleFileInput(event) {
    this.selectedFile = event.target.files[0]
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    }
    reader.readAsDataURL(this.selectedFile)
  }
  handleFileInputComment(event, item) {
    item.imgCommentFile = event.target.files[0]
    var reader = new FileReader();
    reader.onload = (event: any) => {
      item.imgCommentURL = event.target.result;
      this.commentFocus();
    }
    reader.readAsDataURL(item.imgCommentFile)

  }
  handleFileInputItem(event, item) {
    item.image_url_file = event.target.files[0]
    var reader = new FileReader();
    reader.onload = (event: any) => {
      item.image_url = event.target.result;
    }
    reader.readAsDataURL(item.image_url_file)
  }
  handleFileInputItemReply(event, itemReply) {
    itemReply.image_url_file = event.target.files[0]
    var reader = new FileReader();
    reader.onload = (event: any) => {
      itemReply.image = event.target.result;
    }
    reader.readAsDataURL(itemReply.image_url_file)

  }

  get f() { return this.postForm.controls; }

  // OnClick
  onClickDelImgComment(item) {
    item.imgCommentURL = null
  }

  tagUser() {
    this.new_post_users_null = false
  }

  onSubmit() {
    this.submitted = true;
    if (this.postForm.invalid) {
      this.new_post_users_null = true
      return;
    }

    const fd = new FormData();
    var new_post_users = []

    var carer_patients = []
    if (this.postForm.value.carer_patients != null) {
      carer_patients = this.postForm.value.carer_patients
    }

    //เช็คว่ามีการแก้ไข tag carer หรือไม่
    var check_post_user_carer: boolean

    if (this.carer_patients_tag.length != carer_patients.length) {
      check_post_user_carer = true
    }
    else {
      var flag = []
      carer_patients.map((o, i) => {
        var find_user = this.carer_patients_tag.find(carer_patient => carer_patient.user_id === o.user_id);
        if (typeof find_user == 'undefined') {
          flag.push(find_user)
        }
      });
      if (flag.length != 0) {
        check_post_user_carer = true
      } else {
        check_post_user_carer = false
      }
    }
    /************************* */

    if (carer_patients != null) {
      for (let i = 0; i < carer_patients.length; i++) {
        new_post_users.push({ user_id: carer_patients[i].user_id })
      }
    }

    var doctor_patients = []

    if (this.postForm.value.doctor_patients != null) {
      doctor_patients = this.postForm.value.doctor_patients
    }

    //เช็คว่ามีการแก้ไข tag doctor หรือไม่
    var check_post_user_doctor: boolean
    if (this.doctor_patients_tag.length != doctor_patients.length) {
      check_post_user_doctor = true
    }
    else {
      var flag = []
      doctor_patients.map((o, i) => {
        var find_user = this.doctor_patients_tag.find(doctor_patient => doctor_patient.user_id === o.user_id);
        if (typeof find_user == 'undefined') {
          flag.push(find_user)
        }
      });
      if (flag.length != 0) {
        check_post_user_doctor = true
      } else {
        check_post_user_doctor = false
      }
    }
    /************************* */

    if (doctor_patients != null) {
      for (let i = 0; i < doctor_patients.length; i++) {
        new_post_users.push({ user_id: doctor_patients[i].user_id })
      }
    }

    if (this.postForm.value.id) {
      if (check_post_user_doctor == true || check_post_user_carer == true) { //ถ้าแก้ไข tag
        if (new_post_users.length != 0) {
          for (let i = 0; i < new_post_users.length; i++) {
            fd.append('post[new_post_users][][user_id]', new_post_users[i].user_id)
          }
        }
        else {
          this.new_post_users_null = true
          return false
        }
      }
    }
    else {
      if (new_post_users.length != 0) {
        for (let i = 0; i < new_post_users.length; i++) {
          fd.append('post[new_post_users][][user_id]', new_post_users[i].user_id)
        }
      }
      else {
        this.new_post_users_null = true
        return false
      }
    }

    swal({
      title: 'ยืนยันการบันทึก',
      text: "คุณต้องการบันทึกข้อมูลใช่หรือไม่ ?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#36a3f7',
      cancelButtonColor: '#f4516c',
      confirmButtonText: 'ใช่, บันทึกข้อมูล',
      cancelButtonText: 'ยกเลิก'
    }).then((result) => {
      if (result.value) {
        if (this.selectedFile != null) {
          fd.append('post[image]', this.selectedFile, this.selectedFile.name)
        }
        else {
          fd.append('post[image]', '')
        }
        fd.append('post[title]', this.postForm.value.title)
        fd.append('post[content]', this.postForm.value.content)
        fd.append('post[creator_user_id]', this.user.id)
        fd.append('post[patient_id]', this.patient_id)

        if (this.postForm.value.id) {
          var id = this.postForm.value.id
        }
        // return false
        this.patientPostService.submitPost(fd, id).subscribe(res => {
          if (res['error']) {
            swal({
              type: 'warning',
              title: 'การบันทึกข้อมูลผิดพลาด',
              showConfirmButton: true,
            })
          }
          else {
            swal({
              type: 'success',
              title: 'บันทึกข้อมูลเรียบร้อยแล้ว',
              showConfirmButton: false,
              timer: 1500
            })
            this.getPatientPosts();
            this.setForm()
            this.submitted = false
            this.global.loadScript('assets/script/modal.js');
          }
        })
      }
    })
  }

  onClickComment(post_id: any) {
    if (this.post_id == post_id) {
      this.showComment = !this.showComment
    }
    else {
      this.showComment = true
    }
    this.post_id = post_id
    this.patientPosts.forEach(item => {
      item.post_replies.forEach(itemReply => {
        itemReply.edit = false

        if (itemReply.image_url) {
          itemReply.image = this.api_url + itemReply.image_url
        } else {
          itemReply.image = null
        }
      })
    });
  }

  onClickNewComment(item, index) {
    const fd = new FormData();
    swal({
      title: 'ยืนยันการบันทึก',
      text: "คุณต้องการเพิ่มคอมเมนต์ใช่หรือไม่ ?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#36a3f7',
      cancelButtonColor: '#f4516c',
      confirmButtonText: 'ใช่, บันทึกข้อมูล',
      cancelButtonText: 'ยกเลิก'
    }).then((result) => {
      if (result.value) {
        if (item.imgCommentFile) {
          fd.append('post_reply[image]', item.imgCommentFile, item.imgCommentFile.name)
        }
        if (item.newcomment) {
          fd.append('post_reply[content]', item.newcomment)
        }
        fd.append('post_reply[post_id]', item.id)
        fd.append('post_reply[creator_user_id]', this.user.id)
        this.patientPostReplyService.submitPostReply(fd).subscribe(res => {
          if (res['error']) {
            swal({
              type: 'warning',
              title: 'การบันทึกข้อมูลผิดพลาด',
              showConfirmButton: true,
            })
          }
          else {
            swal({
              type: 'success',
              title: 'บันทึกข้อมูลเรียบร้อยแล้ว',
              showConfirmButton: false,
              timer: 1500
            })
            this.imgCommentURL = null
            this.model.comment = ''
            this.getPatientPosts();
            this.setForm()
            this.submitted = false
            this.global.loadScript('assets/script/modal.js');
          }
        })
      }
    })
  }

  onClickItemEdit(item) {
    item.edit = true
  }
  onClickItemDone(item, isEdit) {

    item.edit = false
    if (isEdit) {
      const fd = new FormData();
      swal({
        title: 'ยืนยันการบันทึก',
        text: "คุณต้องการบันทึกข้อมูลใช่หรือไม่ ?",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#36a3f7',
        cancelButtonColor: '#f4516c',
        confirmButtonText: 'ใช่, บันทึกข้อมูล',
        cancelButtonText: 'ยกเลิก'
      }).then((result) => {
        if (result.value) {
          if (item.image_url_file != null && item.image_url != null) {
            fd.append('post[image]', item.image_url_file, item.image_url_file.name)
          }
          else if (item.image_url == null) {
            fd.append('post[image]', '')
          }
          fd.append('post[title]', item.title)
          fd.append('post[content]', item.content)
          fd.append('post[creator_user_id]', this.user.id)
          fd.append('post[patient_id]', item.patient_id)

          this.patientPostService.submitPost(fd, item.id).subscribe(res => {
            if (res['error']) {
              swal({
                type: 'warning',
                title: 'การบันทึกข้อมูลผิดพลาด',
                showConfirmButton: true,
              })
            }
            else {
              swal({
                type: 'success',
                title: 'บันทึกข้อมูลเรียบร้อยแล้ว',
                showConfirmButton: false,
                timer: 1500
              })
              this.getPatientPosts();
              this.setForm()
              this.submitted = false
              this.global.loadScript('assets/script/modal.js');
            }
          })
        }
      })
    } else {
      this.getPatientPosts();
    }
  }

  onClickItemDel(item) {
    swal({
      title: 'ยืนยันการลบข้อมูล',
      text: "คุณต้องการลบข้อมูลใช่หรือไม่ ?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#36a3f7',
      cancelButtonColor: '#f4516c',
      confirmButtonText: 'ใช่, ลบข้อมูล',
      cancelButtonText: 'ยกเลิก'
    }).then((result) => {
      if (result.value) {
        this.patientPostService.deletePost(item.id).subscribe(res => {
          swal({
            type: 'success',
            title: 'ลบข้อมูลเรียบร้อยแล้ว',
            showConfirmButton: false,
            timer: 1500
          })
          this.getPatientPosts();
          this.submitted = false
          this.global.loadScript('assets/script/modal.js');
        })
      }
    })
  }

  onClickItemDelImg() {
    this.selectedFile = null
    this.imageUrl = './assets/images/placeholder-600x400.png';
  }

  // action reply
  onClickItemReplyEdit(item_reply) {
    item_reply.edit = true
  }

  onClickItemReplyDone(item_reply, isEdit) {
    item_reply.edit = false
    if (isEdit) {
      const fd = new FormData();
      swal({
        title: 'ยืนยันการบันทึก',
        text: "คุณต้องการบันทึกข้อมูลใช่หรือไม่ ?",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#36a3f7',
        cancelButtonColor: '#f4516c',
        confirmButtonText: 'ใช่, บันทึกข้อมูล',
        cancelButtonText: 'ยกเลิก'
      }).then((result) => {
        if (result.value) {
          if (item_reply.image_url_file != null && item_reply.image_url != null) {
            fd.append('post_reply[image]', item_reply.image_url_file, item_reply.image_url_file.name)
          } else if (item_reply.image_url == null) {
            fd.append('post_reply[image]', '')
          }
          fd.append('post_reply[content]', item_reply.content)
          fd.append('post_reply[post_id]', item_reply.post_id)
          fd.append('post_reply[creator_user_id]', item_reply.creator_user_id)

          this.patientPostReplyService.submitPostReply(fd, item_reply.id).subscribe(res => {
            if (res['error']) {
              swal({
                type: 'warning',
                title: 'การบันทึกข้อมูลผิดพลาด',
                showConfirmButton: true,
              })
            }
            else {
              swal({
                type: 'success',
                title: 'บันทึกข้อมูลเรียบร้อยแล้ว',
                showConfirmButton: false,
                timer: 1500
              })
              this.getPatientPosts();
              this.showComment = true
              this.post_id = item_reply.post_id

              this.setForm()
              this.submitted = false
              this.global.loadScript('assets/script/modal.js');
            }
          })
        }
      })
    } else {
      this.getPatientPosts();
    }
  }

  onClickItemReplyDel(item_reply) {
    swal({
      title: 'ยืนยันการบันทึก',
      text: "คุณต้องการลบข้อมูลใช่หรือไม่ ?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#36a3f7',
      cancelButtonColor: '#f4516c',
      confirmButtonText: 'ใช่, ลบข้อมูล',
      cancelButtonText: 'ยกเลิก'
    }).then((result) => {
      if (result.value) {
        this.patientPostReplyService.deletePostReply(item_reply.id).subscribe(res => {
          swal({
            type: 'success',
            title: 'ลบข้อมูลเรียบร้อยแล้ว',
            showConfirmButton: false,
            timer: 1500
          })
          this.getPatientPosts();
          this.submitted = false
          this.global.loadScript('assets/script/modal.js');
        })
      }
    })
  }
  onClickItemReplyDelImg(item_reply) {
    item_reply.image_url = null
    item_reply.image = null
  }

  // service
  getPatientPosts() {
    this.patientPostService.getPosts(this.patient_id).subscribe(data => {

      this.patientPosts = data
      this.patientPosts.forEach(item => {
        var post_user = ''
        var carer_patients = []
        var doctor_patients = []
        item.post_users.forEach(element => {
          if (post_user != '') {
            post_user += ', '
          }
          post_user += element.user_display_name

          element.name = element.user_display_name
          if (element.user_role_id == 4) {
            carer_patients.push(element)
          } else if (element.user_role_id == 2) {
            doctor_patients.push(element)
          }
        });
        item.doctor_patients = doctor_patients
        item.carer_patients = carer_patients

        item.post_users = post_user
        var date = this.global.setDateThaiLongDataFormServer(item.created_at)
        var time = this.global.settimeDataFormServer(item.created_at)
        item.date = date + ' ' + time
        if (item.image_url) {
          item.image_url = this.api_url + item.image_url
        }
        else {
          item.image_url = null
        }

        // set data
        item.creator_img = "url(./assets/images/user/user-male.png)"
        if (item.creator_user.doctor) {
          if (item.creator_user.doctor.image_url.length > 0)
            item.creator_img = 'url("' + this.api_url + item.creator_user.doctor.image_url + '")'
        } else if (item.creator_user.carer) {
          if (item.creator_user.carer.image_url.length > 0)
            item.creator_img = 'url("' + this.api_url + item.creator_user.carer.image_url + '")'
        }
        item.edit = false;

        // set data post reply
        item.post_replies.forEach(item_reply => {
          var date = this.global.setDateThaiLongDataFormServer(item_reply.created_at)
          var time = this.global.settimeDataFormServer(item_reply.created_at)
          item_reply.date = date + ' ' + time
          if (item_reply.image_url) {
            item_reply.image = this.api_url + item_reply.image_url
          } else {
            item_reply.image = null
          }
          item_reply.creator_img = "url(./assets/images/user/user-male.png)"

          if (item_reply.creator_user.doctor) {
            if (item_reply.creator_user.doctor.image_url.length > 0)
              item_reply.creator_img = 'url("' + this.api_url + item_reply.creator_user.doctor.image_url + '")'
          } else if (item_reply.creator_user.carer) {
            if (item_reply.creator_user.carer.image_url.length > 0)
              item_reply.creator_img = 'url("' + this.api_url + item_reply.creator_user.carer.image_url + '")'
          }
        });
      });
    })
  }

  selectImage(id) {
    document.getElementById(id).click(); // Click on the checkbox
  }

  getCarerPatients() {
    var filters = {
      patient_id: this.patient_id
    }

    this.patientService.getCarerPatients(filters).subscribe(data => {
      this.carer_patients = data
      this.carer_patients.forEach(element => {
        element.name = element.carer.display_name
        element.user_id = element.carer.user_id
      });

    });

  }

  getDoctorPatients() {
    var filters = {
      patient_id: this.patient_id
    }

    this.patientService.getDoctorPatients(filters).subscribe(data => {
      this.doctor_patients = data
      this.doctor_patients.forEach(element => {
        element.name = element.doctor.display_name
        element.user_id = element.doctor.user_id
      });
    });
  }

}
