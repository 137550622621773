import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { service_url } from "./service-url";

@Injectable({
  providedIn: 'root'
})
export class MedicinePackageService {

  api_url: any
  constructor(private http: HttpClient) {
    this.api_url = service_url.api_url
  }

  getMedicinePackages(filters = {}) {
    let params = new HttpParams();
    Object.keys(filters).forEach(function (key) {
      params = params.append(key, filters[key]);
    });
    return this.http.get(this.api_url + '/medicine_packages', { params: params })
  }

  showMedicinePackage(medicine_package_id) {
    return this.http.get(this.api_url + '/medicine_packages/' + medicine_package_id)
  }

  submitMedicinePackage(params, id = '') {
    if (id != '') {
      return this.http.put(this.api_url + '/medicine_packages/' + id, params)
    }
    else {
      return this.http.post(this.api_url + '/medicine_packages', params)
    }
  }

  deleteMedicinePackage(medicine_package_id) {
    return this.http.delete(this.api_url + '/medicine_packages/' + medicine_package_id)
  }
}
