import {
  Component,
  OnInit,
  ViewEncapsulation,
  AfterViewInit
} from "@angular/core";
import { UserService } from "../../services/user.service";
import { Router, NavigationEnd } from "@angular/router";
import { Location } from "@angular/common";
import { GlobalService } from "../../services/global.service";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";

@Component({
  selector: "app-header-nav",
  templateUrl: "./header-nav.component.html",
  styleUrls: ["./header-nav.component.scss"]
})
export class HeaderNavComponent implements OnInit {
  sessions: any;
  user_login: any = {};
  noSubMenu: boolean = true;
  _onLangChange: any;
  currentLang: string;
  constructor(
    private userService: UserService,
    private router: Router,
    private location: Location,
    private global: GlobalService,
    private translate: TranslateService
  ) {
    this.sessions = JSON.parse(sessionStorage.getItem("sessions"));

    this.translate.use("th");
    this._onLangChange = this.translate.onLangChange.subscribe(
      (event: LangChangeEvent) => {
        this.currentLang = this.translate.currentLang;
      }
    );
  }
  ngOnInit() {
    this.showUserLogin();
    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      let currentUrl = this.location.path();
      let currentUrlLength = currentUrl.split("/");
      if (currentUrlLength.length > 2) {
        this.noSubMenu = false;
      } else {
        this.noSubMenu = true;
      }
    });
  }

  ngOnDestroy() {
    this._onLangChange.unsubscribe();
  }

  switchLanguage(language: string) {
    this.translate.use(language);
  }

  showUserLogin() {
    this.userService.showUser(this.sessions.user_id).subscribe(data => {
      this.user_login = data;
    });
  }

  logout() {
    this.router.navigate(["/login"]);
    sessionStorage.removeItem("sessions");
    sessionStorage.removeItem("patient_id");
  }
}
