import { Component, OnInit, ViewChild } from "@angular/core";
import { MedicineService } from "../../../../services/medicine.service";
import { MedicinePackageService } from "../../../../services/medicine-package.service";
import { MedicineUnitService } from "../../../../services/medicine-unit.service";
import { MedicineGroupService } from "../../../../services/medicine-group.service";
import { MedicineTypeService } from "../../../../services/medicine-type.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { GlobalService } from "../../../../services/global.service";
import swal from "sweetalert2";
import { service_url } from "../../../../services/service-url";
import { MatTableDataSource, MatSort, MatPaginator } from "@angular/material";
import { Papa } from "ngx-papaparse";
import { timeout } from "q";

@Component({
  selector: "app-setting-medicine-item",
  templateUrl: "./setting-medicine-item.component.html",
  styleUrls: ["./setting-medicine-item.component.scss"]
})
export class SettingMedicineItemComponent implements OnInit {
  medicines: MatTableDataSource<any>;
  medicine_groups: any;
  medicine_types: any;
  medicine_packages: any;
  medicine_units: any;
  medicinesForm: FormGroup;
  filtersForm: FormGroup;
  submitted = false;
  api_url = service_url.api_url;
  selectedFile: File = null;
  imageUrl: string = "./assets/images/no-images.jpg";
  hospital_id: any;
  user_id: any;

  limit: number = 25;
  totalLength: number = 0;
  pageIndex: number = 0;
  pageLimit: number[] = [25, 50, 100];

  displayedColumns: string[] = [
    "index",
    "medicine_group_name",
    "medicine_type_name",
    "generic_name",
    "trade_name",
    "medicine_package_name",
    "dose_unit",
    "action"
  ];
  @ViewChild(MatSort) sort: MatSort;
  // @ViewChild(MatPaginator) paginator: MatPaginator
  constructor(
    private medicineService: MedicineService,
    private MedicineGroupService: MedicineGroupService,
    private MedicineTypeService: MedicineTypeService,
    private MedicineUnitService: MedicineUnitService,
    private MedicinePackageService: MedicinePackageService,
    private formBuilder: FormBuilder,
    private Global: GlobalService,
    private papa: Papa
  ) {
    var sessions = JSON.parse(sessionStorage.getItem("sessions"));
    this.user_id = sessions.user_id;
    if (sessions.user.role_id == 1) {
      this.hospital_id = null;
    } else {
      this.hospital_id = sessions.user.doctor.hospital_id;
    }
  }

  ngOnInit() {
    this.getMedicineGroups();
    this.getMedicineTypes();
    this.getMedicinePackages();
    this.getMedicineUnits();
    this.setFiltersForm();
    this.getMedicines();
    this.setForm();
  }

  uploadCSV(evt) {
    var files = evt.target.files; // FileList object
    var file = files[0];
    var reader = new FileReader();
    reader.readAsText(file);
    reader.onload = (event: any) => {
      var csv = event.target.result; // Content of CSV file
      this.papa.parse(csv, {
        skipEmptyLines: true,
        header: true,
        complete: results => {
          // return false
          results.data.forEach(element => {
            element.dosage = "-";
            element.dose = 1;
            // if (this.hospital_id != null) {
            //   element.hospital_id = this.hospital_id
            // }
            element.hospital_id = this.hospital_id;
            element.user_id = this.user_id;
          });
          var medicines = results.data;
          this.medicineService.importObjects(results.data).subscribe(res => {
            swal({
              type: "success",
              title: "บันทึกข้อมูลเรียบร้อยแล้ว",
              showConfirmButton: false,
              timer: 1500
            });
            this.getMedicines();
          });
        }
      });
    };
  }

  setFiltersForm() {
    this.filtersForm = this.formBuilder.group({
      medicine_group_id: "",
      medicine_type_id: "",
      keywords: ""
    });
  }

  setForm() {
    this.medicinesForm = this.formBuilder.group({
      medicine_group_id: ["", Validators.required],
      medicine_type_id: ["", Validators.required],
      generic_name: ["", Validators.required],
      trade_name: ["", Validators.required],
      // dosage: ['', Validators.required],
      medicine_package_id: [null],
      dose: ["", Validators.required],
      medicine_unit_id: [""],
      id: ""
    });
  }

  showForm(medicine = null) {
    this.submitted = false;
    this.selectedFile = null;
    this.imageUrl = "./assets/images/no-images.jpg";
    this.getMedicinePackages();
    this.getMedicineUnits();
    if (medicine != null) {
      // var trade_name = ''
      // var stripedHtml = medicine.trade_name.replace('<sup>', '^');
      // trade_name = stripedHtml
      // trade_name = trade_name.replace('</sup>', '');

      // this.medicinesForm = this.formBuilder.group({
      //   medicine_group_id: [medicine.medicine_group_id, Validators.required],
      //   medicine_type_id: [medicine.medicine_type_id, Validators.required],
      //   generic_name: [medicine.generic_name, Validators.required],
      //   trade_name: [medicine.trade_name, Validators.required],
      //   // dosage: [medicine.dosage, Validators.required],
      //   medicine_package_id: [medicine.medicine_package_id, Validators.required],
      //   dose: [medicine.dose, Validators.required],
      //   medicine_unit_id: [medicine.medicine_unit_id, Validators.required],
      //   id: medicine['id']
      // });
      console.log(medicine);
      // medicine.medicine_package_id = 92
      this.medicinesForm.patchValue(medicine);

      if (medicine["image_url"] != "") {
        this.imageUrl = this.api_url + "/" + medicine["image_url"];
      } else {
        this.imageUrl = "./assets/images/no-images.jpg";
      }
    } else {
      this.setForm();
      this.selectedFile = null;
      this.imageUrl = "./assets/images/no-images.jpg";
    }
  }

  changePage(event) {
    this.pageIndex = event.pageIndex;
    this.getMedicines();
  }

  getMedicines() {
    var filters = {
      datatable: true,
      limit: this.limit,
      offset: this.limit * this.pageIndex
    };
    if (this.hospital_id != null) {
      filters["hospital_id"] = this.hospital_id;
    }

    var filtersForm = this.filtersForm.value;
    if (filtersForm.keywords != "") {
      filters["keywords"] = filtersForm.keywords;
      filters["offset"] = 0;
    }
    if (filtersForm.medicine_group_id != "") {
      filters["medicine_group_id"] = filtersForm.medicine_group_id;
    }
    if (filtersForm.medicine_type_id != "") {
      filters["medicine_type_id"] = filtersForm.medicine_type_id;
    }

    this.medicineService.getMedicines(filters).subscribe(data => {
      let medicines;
      medicines = data["data"];
      this.totalLength = data["total"];
      let array = medicines.map((item, index) => {
        item.dose_unit = item.dose + " " + item.medicine_unit_name;
        item.index = index + 1;
        return item;
      });
      this.medicines = new MatTableDataSource(array);
      this.medicines.sort = this.sort;
      // this.medicines.paginator = this.paginator
    });
  }

  getMedicineGroups() {
    this.MedicineGroupService.getMedicineGroups().subscribe(data => {
      this.medicine_groups = data;
    });
  }

  getMedicineTypes() {
    this.MedicineTypeService.getMedicineTypes().subscribe(data => {
      this.medicine_types = data;
    });
  }

  getMedicinePackages() {
    this.MedicinePackageService.getMedicinePackages().subscribe(data => {
      this.medicine_packages = data;
    });
  }

  getMedicineUnits() {
    this.MedicineUnitService.getMedicineUnits().subscribe(data => {
      this.medicine_units = data;
    });
  }

  get f() {
    return this.medicinesForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    if (this.medicinesForm.invalid) {
      return;
    }
    const fd = new FormData();
    swal({
      title: "ยืนยันการบันทึก",
      text: "คุณต้องการบันทึกข้อมูลใช่หรือไม่ ?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#36a3f7",
      cancelButtonColor: "#f4516c",
      confirmButtonText: "ใช่, บันทึกข้อมูล",
      cancelButtonText: "ยกเลิก"
    }).then(result => {
      if (result.value) {
        let trade_name = this.setTradeName(
          this.medicinesForm.controls.trade_name.value
        );
        // return false
        if (this.selectedFile != null) {
          fd.append(
            "medicine[image]",
            this.selectedFile,
            this.selectedFile.name
          );
        }
        fd.append(
          "medicine[medicine_group_id]",
          this.medicinesForm.controls.medicine_group_id.value
        );
        fd.append(
          "medicine[medicine_type_id]",
          this.medicinesForm.controls.medicine_type_id.value
        );
        fd.append(
          "medicine[generic_name]",
          this.medicinesForm.controls.generic_name.value
        );
        fd.append("medicine[trade_name]", trade_name);
        fd.append("medicine[dosage]", "-");
        if (this.medicinesForm.controls.medicine_package_id.value != null) {
          fd.append(
            "medicine[medicine_package_id]",
            this.medicinesForm.controls.medicine_package_id.value
          );
        }
        fd.append("medicine[dose]", this.medicinesForm.controls.dose.value);
        fd.append(
          "medicine[medicine_unit_id]",
          this.medicinesForm.controls.medicine_unit_id.value
        );
        if (this.hospital_id != null) {
          fd.append("medicine[hospital_id]", this.hospital_id);
        }
        fd.append("medicine[user_id]", this.user_id);
        var medicine_id = "";
        if (this.medicinesForm.controls.id) {
          medicine_id = this.medicinesForm.controls.id.value;
        }
        this.medicineService.submitMedicine(fd, medicine_id).subscribe(res => {
          if (res["error"]) {
            swal({
              type: "warning",
              title: "การบันทึกข้อมูลผิดพลาด",
              showConfirmButton: true
            });
          } else {
            swal({
              type: "success",
              title: "บันทึกข้อมูลเรียบร้อยแล้ว",
              showConfirmButton: false,
              timer: 1500
            });
            this.getMedicines();
            this.setForm();
            this.submitted = false;
            this.selectedFile = null;
            this.Global.loadScript("assets/script/modal.js");
          }
        });
      }
    });
  }

  setTradeName(trade_name) {
    var trade_names: string = trade_name;
    var character_arr = [];
    for (let trade_name of trade_names) {
      var character = trade_name;
      character_arr.push(character);
    }

    for (let i = 0; i < character_arr.length; i++) {
      const element = character_arr[i];
      if (element == "^") {
        character_arr[i] = "<sup>";
        character_arr[i + 1] = character_arr[i + 1] + "</sup>";
      }
    }
    var result = character_arr.join("");
    return result;
  }

  delete(id) {
    swal({
      title: "ยืนยันการลบข้อมูล",
      text: "คุณต้องการลบข้อมูลใช่หรือไม่ ?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#36a3f7",
      cancelButtonColor: "#f4516c",
      confirmButtonText: "ใช่, ลบข้อมูล",
      cancelButtonText: "ยกเลิก"
    }).then(result => {
      if (result.value) {
        this.medicineService.deleteMedicine(id).subscribe(res => {
          swal({
            type: "success",
            title: "ลบข้อมูลเรียบร้อยแล้ว",
            showConfirmButton: false,
            timer: 1500
          });
          this.getMedicines();
        });
      }
    });
  }

  handleFileInput(event) {
    this.selectedFile = event.target.files[0];
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    };
    reader.readAsDataURL(this.selectedFile);
  }
}
