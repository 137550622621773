import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  user: any;
  patient_id: any;
  hospital_id: any;
  month_thai_arr: any;
  years_list: any = [];
  doctor_id: any;
  sessions: any;

  constructor(
    private router: Router
  ) {
    this.month_thai_arr = [
      { id: 1, name: 'มกราคม' },
      { id: 2, name: 'กุมภาพันธ์' },
      { id: 3, name: 'มีนาคม' },
      { id: 4, name: 'เมษายน' },
      { id: 5, name: 'พฤษภาคม' },
      { id: 6, name: 'มิถุนายน' },
      { id: 7, name: 'กรกฎาคม' },
      { id: 8, name: 'สิงหาคม' },
      { id: 9, name: 'กันยายน' },
      { id: 10, name: 'ตุลาคม' },
      { id: 11, name: 'พฤศจิกายน' },
      { id: 12, name: 'ธันวาคม' },
    ]

    let year = new Date().getFullYear();
    year = year + 543
    for (let i = 1; i <= 5; i++) {
      this.years_list.push(year-- )
    }
  }

  setUser() {
    this.patient_id = sessionStorage.getItem('patient_id');
    this.user = this.sessions.user;
    if (this.sessions.user.role_id == 3 || this.sessions.user.role_id == 2) {
      this.hospital_id = this.sessions.user.doctor.hospital_id
    }
    if (this.sessions.user.role_id == 2) {
      this.doctor_id = this.sessions.user.doctor.id
    }
  }

  scrollToTop() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });

  }

  setMonthThaiShort(month = null) { // month 10 to ตุลาคม
    let month_current
    if (month != null) {
      month_current = month
    } else {
      month_current = new Date().getMonth() + 1;
    }

    let month_thai
    this.month_thai_arr.forEach(element => {
      if (month_current == element.id) {
        month_thai = element.name
      }
    });

    return month_thai
  }

  setDateFormatShort(date_param = '', language) {
    let date_sub = date_param.split("-");
    let year = (language == 'th' ? +date_sub[0] + 543 : +date_sub[0])
    let month = date_sub[1]
    let day = date_sub[2]

    let result = day + "/" + month + "/" + year.toString().substring(2, 4)
    return result
  }

  setDateFormatThaiLongMonth(date_param = '') { // 2019-02-26 to 26 กุมภาพันธ์ 2562
    let date
    if (date_param) {
      date = date_param
    }
    else {
      date = this.getDateNowYMD()
    }

    let date_sub = date.split("-");
    let year = +date_sub[0] + 543
    let month = date_sub[1]

    month = this.setMonthThaiShort(month)
    let day = date_sub[2]

    let result = day + " " + month + " " + year
    return result
  }

  setDateThaiLongDataFormServer(created_at = '') {
    let date = new Date(created_at)
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    let day = date.getDate()

    let date_param = year + '-' + month + '-' + day
    date_param = this.setDateFormatThaiLongMonth(date_param)
    return date_param
  }

  setDateThaiShotDataFormServer(created_at = '', language) {
    let date = new Date(created_at)
    let year = date.getFullYear()
    year = (language == 'th' ? +year + 543 : +year)
    let month = date.getMonth() + 1
    let day = date.getDate()

    let m
    if (month < 10) {
      m = '0' + month
    }
    else {
      m = month
    }

    let d
    if (day < 10) {
      d = '0' + day
    }
    else {
      d = day
    }
    let date_param = d + '/' + m + '/' + year
    return date_param
  }

  setDateNumberDMY(date) { // 2019-02-26 to 26/02/26
    let date_sub = date.split("-");
    let year = +date_sub[0]
    let month = date_sub[1]
    let day = date_sub[2]

    let result = day + "/" + month + "/" + year
    return result
  }

  setDateNumberYMD(date) { // 26/02/26 to 2019-02-26
    let date_sub = date.split("/");
    let day = date_sub[0]
    let month = date_sub[1]
    let year = date_sub[2]

    let result = year + "-" + month + "-" + day
    return result
  }

  getDateNowYMD() { // current date 2018-02-26
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1; //January is 0!
    let yyyy = today.getFullYear();

    let m
    if (mm < 10) {
      m = '0' + mm
    }
    else {
      m = mm
    }

    let d
    if (dd < 10) {
      d = '0' + dd
    }
    else {
      d = dd
    }
    let date = yyyy + '-' + m + '-' + d;
    return date
  }

  settimeDataFormServer(created_at = '') {
    let date = new Date(created_at)
    let Hours = date.getHours()
    let Minutes = date.getMinutes()

    let h
    if (Hours < 10) {
      h = '0' + Hours
    }
    else {
      h = Hours
    }

    let m
    if (Minutes < 10) {
      m = '0' + Minutes
    }
    else {
      m = Minutes
    }

    return h + ':' + m
  }

  loadScript(scriptUrl: string): any {
    return new Promise((resolve, reject) => {
      const scriptElement = document.createElement('script');
      scriptElement.src = scriptUrl;
      scriptElement.onload = resolve;
      document.body.appendChild(scriptElement);
    })
  }

  validateIdCard(value) {
    if (value.length != 13) return false;
    let sum = 0
    for (let i = 0; i < 12; i++)
      sum += parseFloat(value.charAt(i)) * (13 - i); if ((11 - sum % 11) % 10 != parseFloat(value.charAt(12)))
      return false; return true;
  }

  calculateAge(birthDate, currentDate) {
    let timeDiff = Math.abs(new Date(currentDate).getTime() - new Date(birthDate).getTime());
    const age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);

    return age
  }
}
