import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { service_url } from "./service-url";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  api_url: any
  constructor(private http: HttpClient) {
    this.api_url = service_url.api_url
  }

  getUsers() {
    return this.http.get(this.api_url + '/users')
  }

  showUser(user_id) {
    return this.http.get(this.api_url + '/users/' + user_id)
  }

  delUser(user_id) {
    return this.http.delete(this.api_url + '/users/' + user_id)
  }
}
