import { Component, OnInit, ViewChild } from '@angular/core';
import { MedicineUnitService } from "../../../../services/medicine-unit.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalService } from "../../../../services/global.service";
import swal from 'sweetalert2'
import { MatTableDataSource, MatSort, MatPaginator } from "@angular/material";
@Component({
  selector: 'app-setting-medicine-unit',
  templateUrl: './setting-medicine-unit.component.html',
  styleUrls: ['./setting-medicine-unit.component.scss']
})
export class SettingMedicineUnitComponent implements OnInit {
  medicine_units: MatTableDataSource<any>;
  medicinesUnitForm: FormGroup;
  filtersForm: FormGroup;
  submitted = false;

  displayedColumns: string[] = ['index', 'name', 'action']
  @ViewChild(MatSort) sort: MatSort
  @ViewChild(MatPaginator) paginator: MatPaginator
  constructor(
    private MedicineUnitService: MedicineUnitService,
    private formBuilder: FormBuilder,
    private Global: GlobalService
  ) { }

  ngOnInit() {
    this.getMedicineUnits()
    this.setFiltersForm()
    this.setForm()
  }

  setFiltersForm() {
    this.filtersForm = this.formBuilder.group({
      keywords: ''
    });
  }

  setForm() {
    this.medicinesUnitForm = this.formBuilder.group({
      name: ['', Validators.required]
    });
  }

  showForm(medicine_units = null) {
    this.submitted = false;
    if (medicine_units != null) {
      this.medicinesUnitForm = this.formBuilder.group({
        name: [medicine_units.name, Validators.required],
        id: medicine_units['id']
      });
    }
    else {
      this.setForm()
    }
  }

  getMedicineUnits() {
    var filters = {}
    if (this.filtersForm) {
      filters['keywords'] = this.filtersForm.value.keywords
    }

    this.MedicineUnitService.getMedicineUnits(filters).subscribe(data => {
      let medicine_units
      medicine_units = data
      let array = medicine_units.map((item, index) => {
        item.index = index + 1
        return item
      })
      this.medicine_units = new MatTableDataSource(array)
      this.medicine_units.sort = this.sort
      this.medicine_units.paginator = this.paginator
    })
  }

  get f() { return this.medicinesUnitForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.medicinesUnitForm.invalid) {
      return;
    }
    const fd = new FormData();
    swal({
      title: 'ยืนยันการบันทึก',
      text: "คุณต้องการบันทึกข้อมูลใช่หรือไม่ ?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#36a3f7',
      cancelButtonColor: '#f4516c',
      confirmButtonText: 'ใช่, บันทึกข้อมูล',
      cancelButtonText: 'ยกเลิก'
    }).then((result) => {
      if (result.value) {
        fd.append('medicine_unit[name]', this.medicinesUnitForm.controls.name.value)
        var medicine_unit_id = ''
        if (this.medicinesUnitForm.controls.id) {
          medicine_unit_id = this.medicinesUnitForm.controls.id.value
        }
        this.MedicineUnitService.submitMedicineUnit(fd, medicine_unit_id).subscribe(res => {
          if (res['error']) {
            swal({
              type: 'warning',
              title: 'การบันทึกข้อมูลผิดพลาด',
              showConfirmButton: true,
            })
          }
          else {
            swal({
              type: 'success',
              title: 'บันทึกข้อมูลเรียบร้อยแล้ว',
              showConfirmButton: false,
              timer: 1500
            })
            this.getMedicineUnits();
            this.setForm()
            this.submitted = false
            this.Global.loadScript('assets/script/modal.js');
          }
        })
      }
    })
  }

  delete(id) {
    swal({
      title: 'ยืนยันการลบข้อมูล',
      text: "คุณต้องการลบข้อมูลใช่หรือไม่ ?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#36a3f7',
      cancelButtonColor: '#f4516c',
      confirmButtonText: 'ใช่, ลบข้อมูล',
      cancelButtonText: 'ยกเลิก'
    }).then((result) => {
      if (result.value) {
        this.MedicineUnitService.deleteMedicineUnit(id).subscribe(res => {
          swal({
            type: 'success',
            title: 'ลบข้อมูลเรียบร้อยแล้ว',
            showConfirmButton: false,
            timer: 1500
          })
          this.getMedicineUnits();
        })
      }
    })
  }

}
