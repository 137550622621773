import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PatientTestsService } from "../../../services/patient-tests.service";
import { GlobalService } from "../../../services/global.service";

@Component({
  selector: 'app-patient-test1-detail',
  templateUrl: './patient-test1-detail.component.html',
  styleUrls: ['./patient-test1-detail.component.scss']
})
export class PatientTest1DetailComponent implements OnInit {
  patient_tests_id: any;
  patient_tests: any = {};
  form1: any;
  patient: any = {};

  constructor(
    private route: ActivatedRoute,
    private patientTestsService: PatientTestsService,
    private global: GlobalService,
  ) {
    this.route.queryParams.subscribe(params => {
      this.patient_tests_id = params.test;
    });
  }

  ngOnInit() {
    this.showDetail()
  }

  getForm() {
    this.patientTestsService.getTest1().subscribe(data => {
      this.form1 = data
    })

  }

  showDetail() {
    this.patientTestsService.showPatientTests(this.patient_tests_id).subscribe(data => {
      this.patient_tests = data
      this.patientTestsService.getTest1().subscribe(data => {
        this.form1 = data

        for (let i = 0; i < this.patient_tests.patient_test_values.length; i++) {
          const element = this.patient_tests.patient_test_values[i];

          element.question = this.form1[i].question
          if (element.value == null) {
            element.value = 0
          }

          if (element.has_sub_value == false) {
            element.answer = this.form1[i].answer[element.choice]
          }
          else {
            var answer = ''
            for (let j = 0; j < element.patient_test_sub_values.length; j++) {
              const patient_test_sub_value = element.patient_test_sub_values[j];
              patient_test_sub_value.answer = this.form1[i].answer[patient_test_sub_value.choice]
              if (answer != '') {
                answer += " + "
              }
              answer += patient_test_sub_value.answer
              element.answer = answer
            }
          }

        }
      })
      if (this.patient_tests.total_score >= 0 && this.patient_tests.total_score <= 13) {
        this.patient_tests.score_level = 1
        this.patient_tests.interpret_symptoms = 'อาการของภาวะสมองเสื่อมส่งผลให้ความสามารถในการทำคำสั่งต่าง ๆ ที่ง่าย ๆ ไม่สำเร็จและบกพร่องแทบทั้งหมด'
      }
      else if (this.patient_tests.total_score >= 14 && this.patient_tests.total_score <= 25) {
        this.patient_tests.score_level = 2
        this.patient_tests.interpret_symptoms = 'อาการของภาวะสมองเสื่อมส่งผลให้ความสามารถในการทำคำสั่งต่าง ๆ ที่ง่าย ๆ ช้าลงมากและบกพร่องเป็นส่วนใหญ่ ต้องอาศัยการกระตุ้นอย่างมาก'
      }
      else if (this.patient_tests.total_score >= 26 && this.patient_tests.total_score <= 30) {
        this.patient_tests.score_level = 3
        this.patient_tests.interpret_symptoms = 'อาการของภาวะสมองเสื่อมส่งผลทำให้การทำตามคำสั่งต่าง ๆ ง่าย ๆ ช้าลง และเริ่มมีการบกพร่องบ้างแล้ว'
      }
      this.patient_tests.user = data['user'].display_name
      this.patient_tests.created_date = this.global.setDateFormatThaiLongMonth(this.patient_tests.created_date)
    })
    // this.getForm()

  }


}
