import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExcelService {

  constructor() { }

  public exportAsExcelFile(json1: any[], json2: any[], excelFileName: string): void {
    const worksheet1: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json1);
    const worksheet2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json2);
    const workbook: XLSX.WorkBook = { Sheets: { 'Database-SCIRS APP': worksheet1, 'Database-NPI APP': worksheet2 }, SheetNames: ['Database-SCIRS APP', 'Database-NPI APP'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

}