import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { service_url } from "./service-url";

@Injectable({
  providedIn: 'root'
})
export class PatientTestsService {

  api_url: any
  options = {}

  constructor(private http: HttpClient) {
    this.api_url = service_url.api_url
  }

  getTest1() {
    return this.http.get('./assets/json/test1-th.json')
  }
  getTest2() {
    return this.http.get('./assets/json/test2-th.json')
  }

  getPatientTests(filters) {
    let params = new HttpParams();
    Object.keys(filters).forEach(function (key) {
      params = params.append(key, filters[key]);
    });
    if (filters.id) {
      return this.http.get(this.api_url + '/patient_tests/' + filters.id, { params: params })
    }
    else {
      return this.http.get(this.api_url + '/patient_tests', { params: params })
    }
  }

  showPatientTests(patient_tests_id) {
    return this.http.get(this.api_url + '/patient_tests/' + patient_tests_id)
  }
}
