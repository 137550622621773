import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { MedicineTakingService } from "../../../services/medicine-taking.service";
import { MedicineService } from "../../../services/medicine.service";
import { GlobalService } from "src/app/services/global.service";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
  ValidatorFn
} from "@angular/forms";
import swal from "sweetalert2";
import { MedicineTakingItemService } from "../../../services/medicine-taking-item.service";
import * as _ from "lodash";

@Component({
  selector: "app-patient-medicine",
  templateUrl: "./patient-medicine.component.html",
  styleUrls: ["./patient-medicine.component.scss"]
})
export class PatientMedicineComponent implements OnInit {
  medicine_takings: any;
  date: any;
  medicineTakeForm: FormGroup;
  updateRemainForm: FormGroup;
  submitted = false;
  medicines: any;
  medicine_take_times: any;
  meal_times: any;
  medicine_taking_items: any = [];
  medicine_taking_item_meal_times: any = [];
  new_medicine_taking_items: any = [];
  patient_id: any = sessionStorage.getItem("patient_id");
  medicine_take_time_groups: any;
  medicine: any = {};
  @ViewChild("amount_remain") nameField: ElementRef;
  medicine_taking_item_id_show: any;
  medicine_taking_item: any;
  medicine_taking_item_edit: boolean = false;
  medicine_taking_id: any = null;
  isOpen: any;
  searchable: boolean;
  readonlyAmountPackage: boolean = true;
  validate_meal_times: any = {
    meal_times: false,
    medicine_take_time_group: false
  };
  arr_meal_time_validate: any = [];
  meal_time_master: any = [
    { id: 1, name: "เช้า", time: "08:00" },
    { id: 2, name: "เที่ยง", time: "12:00" },
    { id: 3, name: "เย็น", time: "16:00" },
    { id: 4, name: "ก่อนนอน", time: "20:00" },
    { id: 5, name: "เมื่อมีอาการ", time: "" }
  ];
  user: any;
  constructor(
    private MedicineTakingService: MedicineTakingService,
    private MedicineService: MedicineService,
    private global: GlobalService,
    private formBuilder: FormBuilder,
    private MedicineTakingItemService: MedicineTakingItemService
  ) {
    var sessions = JSON.parse(sessionStorage.getItem("sessions"));
    this.user = sessions.user;
  }

  ngOnInit() {
    this.getMealTimes();
    this.getMedicineTaking();
    this.date = this.global.setDateFormatThaiLongMonth();
    this.setForm();
  }

  setForm() {
    this.medicineTakeForm = this.formBuilder.group({
      medicine_id: [null, Validators.required],
      amount_package: ["", Validators.required],
      amount_total: "",
      amount_take: ["", Validators.required],
      amount_remain: "",
      remark: null,
      medicine_take_time_id: null,
      medicine_take_time_groups: "",
      meal_times: this.createMealTimes(this.meal_time_master),
      id: ""
    });
  }

  // get formArray() {
  //   return this.medicineTakeForm.get('meal_times') as FormArray
  // }

  createMealTimes(meal_times) {
    const arr = meal_times.map(meal_time => {
      return new FormControl(meal_time.selected || false);
    });
    return new FormArray(arr);
  }

  showForm(medicine_taking_item = null, index = "", edit = null) {
    this.submitted = false;
    this.getMedicines();
    this.getMedicineTakeTimeGroups();
    this.arr_meal_time_validate = [];
    if (medicine_taking_item != null) {
      this.medicine_taking_id = medicine_taking_item.medicine_taking_id;
      this.medicine_taking_item = medicine_taking_item;
      this.medicine_taking_item_edit = edit;
      this.isOpen = false;
      this.searchable = false;
      this.medicine = medicine_taking_item.medicine;
      this.readonlyAmountPackage = false;

      this.medicineTakeForm.addControl("medicine", new FormControl(""));
      this.medicineTakeForm.addControl("dose", new FormControl(""));
      this.medicineTakeForm.addControl(
        "medicine_package_name",
        new FormControl("")
      );
      this.medicineTakeForm.addControl(
        "medicine_package_id",
        new FormControl("")
      );
      this.medicineTakeForm.addControl(
        "medicine_unit_name",
        new FormControl("")
      );
      this.medicineTakeForm.addControl("medicine_unit_id", new FormControl(""));
      this.medicineTakeForm.addControl("index", new FormControl(""));

      this.medicineTakeForm.patchValue({
        medicine_id: medicine_taking_item.medicine_name,
        medicine: medicine_taking_item.medicine_id,
        dose: medicine_taking_item.medicine.dose,
        medicine_package_name:
          medicine_taking_item.medicine.medicine_package_name,
        medicine_package_id: medicine_taking_item.medicine.medicine_package_id,
        medicine_unit_name: medicine_taking_item.medicine.medicine_unit_name,
        medicine_unit_id: medicine_taking_item.medicine.medicine_unit_id,
        amount_package: medicine_taking_item.amount_package,
        amount_total: medicine_taking_item.amount_total,
        amount_take: medicine_taking_item.amount_take,
        amount_remain: medicine_taking_item.amount_remain,
        remark: medicine_taking_item.remark,
        medicine_take_time_groups:
          medicine_taking_item.medicine_take_time != null
            ? String(
                medicine_taking_item.medicine_take_time
                  .medicine_take_time_group_id
              )
            : null,
        medicine_take_time_id:
          medicine_taking_item.medicine_take_time != null
            ? String(medicine_taking_item.medicine_take_time_id)
            : null,
        id: medicine_taking_item.id,
        index: index
      });

      this.meal_time_master.map((meal_time, i) => {
        let find = medicine_taking_item.meal_time_arr.filter(
          meal_time_arr => meal_time_arr.meal_time_id == meal_time.id
        );
        if (find.length != 0) {
          this.medicineTakeForm.controls.meal_times["controls"][i].patchValue(
            true
          );
        } else {
          this.medicineTakeForm.controls.meal_times["controls"][i].patchValue(
            false
          );
        }
      });

      medicine_taking_item.meal_time_arr;

      if (medicine_taking_item.medicine_take_time != null) {
        this.getMedicineTakeTimes(
          medicine_taking_item.medicine_take_time.medicine_take_time_group_id
        );
      }
    } else {
      this.setForm();
      this.readonlyAmountPackage = true;
      this.medicine = {};
      this.isOpen = null;
      this.searchable = true;
      this.medicine_taking_item_edit = false;
      this.medicine_taking_item = null;
      var date = this.global.getDateNowYMD();
      this.MedicineTakingService.getMedicineTakings({
        date: date,
        patient_id: this.patient_id
      }).subscribe(data => {
        if (data["length"] == 0) {
          this.medicine_taking_id = null;
        }
      });
    }
  }

  get f() {
    return this.medicineTakeForm.controls;
  }

  mealTimeValidate(meal_times) {
    const meal_times_find = this.arr_meal_time_validate.findIndex(
      item => item == meal_times
    );
    if (meal_times_find == "-1") {
      this.arr_meal_time_validate.push(meal_times);
    } else {
      this.arr_meal_time_validate.splice(meal_times_find, 1);
    }
    this.validate_meal_times.meal_times =
      this.arr_meal_time_validate.length == 0 ? true : false;

    const medicine_take_time_group_find = this.arr_meal_time_validate.filter(
      meal_time => meal_time < 3
    );

    this.validate_meal_times.medicine_take_time_group =
      this.f.medicine_take_time_groups.value == "" &&
      medicine_take_time_group_find.length != 0
        ? true
        : false;

    if (medicine_take_time_group_find.length == 0) {
      this.medicineTakeForm.patchValue({
        medicine_take_time_groups: "",
        medicine_take_time_id: null,
        medicine_take_time_group_id: ""
      });
      this.medicine_take_times = [];
    }
  }

  addItem() {
    this.submitted = true;

    var medicine_taking_items = {
      medicine_id: this.medicineTakeForm.value.medicine_id.id,
      medicine_name:
        this.medicineTakeForm.value.medicine_id.generic_name +
        " " +
        this.medicineTakeForm.value.medicine_id.trade_name +
        " " +
        this.medicineTakeForm.value.medicine_id.dose +
        " " +
        this.medicineTakeForm.value.medicine_id.medicine_unit_name,
      amount_package: this.medicineTakeForm.controls.amount_package.value,
      amount_total: this.medicineTakeForm.controls.amount_total.value,
      amount_take: this.medicineTakeForm.controls.amount_take.value,
      remark: this.medicineTakeForm.controls.remark.value,
      medicine_take_time_id: this.medicineTakeForm.controls
        .medicine_take_time_id.value,
      medicine_take_time_name: null,
      meal_times: [],
      meal_time_arr: [],
      meal_times_name: [],
      medicine: {
        trade_name: this.medicineTakeForm.value.medicine_id.trade_name,
        medicine_unit_name: this.medicineTakeForm.value.medicine_id
          .medicine_unit_name,
        medicine_unit_id: this.medicineTakeForm.value.medicine_id
          .medicine_unit_id,
        medicine_package_name: this.medicineTakeForm.value.medicine_id
          .medicine_package_name,
        medicine_package_id: this.medicineTakeForm.value.medicine_id
          .medicine_package_id,
        dose: this.medicineTakeForm.value.medicine_id.dose
      },
      medicine_take_time: {
        medicine_take_time_group_id: this.medicineTakeForm.controls
          .medicine_take_time_groups.value
      }
    };

    var new_medicine_taking_items = {
      medicine_taking_id: this.medicine_taking_id,
      medicine_id: this.medicineTakeForm.value.medicine_id.id
        ? this.medicineTakeForm.value.medicine_id.id
        : this.medicineTakeForm.value.medicine,
      amount_package: parseFloat(
        this.medicineTakeForm.controls.amount_package.value
      ),
      amount_total: this.medicineTakeForm.controls.amount_total.value,
      amount_take: parseFloat(this.medicineTakeForm.controls.amount_take.value),
      remark: this.medicineTakeForm.controls.remark.value,
      medicine_take_time_id: parseFloat(
        this.medicineTakeForm.controls.medicine_take_time_id.value
      ),
      amount_remain: this.medicineTakeForm.controls.amount_total.value,
      medicine_package_id: this.medicineTakeForm.value.medicine_id
        .medicine_package_id
        ? this.medicineTakeForm.value.medicine_id.medicine_package_id
        : this.medicineTakeForm.value.medicine_package_id,
      medicine_unit_id: this.medicineTakeForm.value.medicine_id.medicine_unit_id
        ? this.medicineTakeForm.value.medicine_id.medicine_unit_id
        : this.medicineTakeForm.value.medicine_unit_id,
      new_medicine_taking_item_meal_times: [],
      id: this.medicineTakeForm.value.id
    };

    var meal_times = this.medicineTakeForm.controls.meal_times.value;
    for (let i = 0; i < meal_times.length; i++) {
      const element = meal_times[i];
      if (element == true) {
        medicine_taking_items.meal_times.push(this.meal_times[i].id);
        medicine_taking_items.meal_times_name.push(this.meal_times[i].name);
        medicine_taking_items.meal_time_arr.push({
          meal_time_id: this.meal_times[i].id
        });
      }
    }
    if (medicine_taking_items.meal_times.length != 0) {
      for (let i = 0; i < medicine_taking_items.meal_times.length; i++) {
        const element = medicine_taking_items.meal_times[i];
        var obj = {
          meal_time_id: element
        };
        new_medicine_taking_items.new_medicine_taking_item_meal_times.push(obj);
      }
    } else {
      this.validate_meal_times.meal_times = true;
      return false;
    }

    const medicine_take_time_group_find = this.arr_meal_time_validate.filter(
      meal_time => meal_time < 3
    );

    if (
      this.medicineTakeForm.controls.medicine_take_time_groups.value != "" &&
      this.medicineTakeForm.controls.medicine_take_time_groups.value != null
    ) {
      var medicine_take_time = this.medicine_take_times.filter(
        medicine_take_time =>
          medicine_take_time.id ==
          this.medicineTakeForm.controls.medicine_take_time_id.value
      );

      if (medicine_take_time.length != 0) {
        medicine_taking_items.medicine_take_time_name =
          medicine_take_time[0].medicine_take_time_group_name +
          " " +
          medicine_take_time[0].name;
      } else {
        medicine_taking_items.medicine_take_time_name = "";
      }
      medicine_taking_items["take_count"] =
        medicine_taking_items.meal_times.length;
      new_medicine_taking_items["take_count"] =
        medicine_taking_items.meal_times.length;

      this.validate_meal_times.medicine_take_time_group = false;
      if (this.medicineTakeForm.controls.medicine_take_time_id.value == null) {
        this.validate_meal_times.medicine_take_time_group = true;
        return false;
      } else {
        this.validate_meal_times.medicine_take_time_group = false;
      }
    } else {
      medicine_taking_items.medicine_take_time_name = "";
      if (
        medicine_take_time_group_find.length != 0 &&
        (this.medicineTakeForm.controls.medicine_take_time_id.value == null ||
          this.medicineTakeForm.controls.medicine_take_time_id.value == "null")
      ) {
        this.validate_meal_times.medicine_take_time_group = true;
        return false;
      } else {
        this.validate_meal_times.medicine_take_time_group = false;
      }
    }

    if (this.medicineTakeForm.invalid) {
      return;
    }

    var i = this.medicineTakeForm.value.index;
    if (typeof i != "undefined") {
      this.medicine_taking_items[i].medicine_take_time_name =
        medicine_taking_items.medicine_take_time_name;
    }

    const new_medicine_taking_items_find = this.new_medicine_taking_items.find(
      item => item.medicine_id === new_medicine_taking_items.medicine_id
    );

    if (typeof new_medicine_taking_items_find == "undefined") {
      this.new_medicine_taking_items.push(new_medicine_taking_items);
    }

    if (typeof i != "undefined") {
      this.medicine_taking_items[
        i
      ].medicine_id = this.medicineTakeForm.value.medicine;
      this.medicine_taking_items[
        i
      ].medicine_name = this.medicineTakeForm.value.medicine_id;
      this.medicine_taking_items[
        i
      ].amount_package = this.medicineTakeForm.controls.amount_package.value;
      this.medicine_taking_items[
        i
      ].amount_total = this.medicineTakeForm.controls.amount_total.value;
      this.medicine_taking_items[
        i
      ].amount_take = this.medicineTakeForm.controls.amount_take.value;
      this.medicine_taking_items[
        i
      ].remark = this.medicineTakeForm.controls.remark.value;
      this.medicine_taking_items[
        i
      ].medicine_take_time_id = this.medicineTakeForm.controls.medicine_take_time_id.value;
      if (this.medicine_taking_items[i].medicine_take_time != null) {
        this.medicine_taking_items[
          i
        ].medicine_take_time.medicine_take_time_group_id = this.medicineTakeForm.controls.medicine_take_time_groups.value;
      } else {
        this.medicine_taking_items[i].medicine_take_time = {};
      }
      this.medicine_taking_items[i].meal_times_name =
        medicine_taking_items.meal_times_name;
      this.medicine_taking_items[i].meal_time_arr =
        medicine_taking_items.meal_time_arr;
      this.medicine_taking_items[i].meal_times =
        medicine_taking_items.meal_times;

      var i_new_item = this.new_medicine_taking_items.findIndex(
        item => item.medicine_id === this.medicineTakeForm.value.medicine
      );

      this.new_medicine_taking_items[
        i_new_item
      ].medicine_id = this.medicineTakeForm.value.medicine;
      this.new_medicine_taking_items[i_new_item].amount_package = parseFloat(
        this.medicineTakeForm.controls.amount_package.value
      );
      this.new_medicine_taking_items[i_new_item].amount_total = parseFloat(
        this.medicineTakeForm.controls.amount_total.value
      );
      this.new_medicine_taking_items[i_new_item].amount_take = parseFloat(
        this.medicineTakeForm.controls.amount_take.value
      );
      this.new_medicine_taking_items[
        i_new_item
      ].remark = this.medicineTakeForm.controls.remark.value;
      this.new_medicine_taking_items[
        i_new_item
      ].medicine_package_id = this.medicineTakeForm.value.medicine_package_id;
      this.new_medicine_taking_items[
        i_new_item
      ].medicine_unit_id = this.medicineTakeForm.value.medicine_unit_id;
      this.new_medicine_taking_items[
        i_new_item
      ].new_medicine_taking_item_meal_times =
        new_medicine_taking_items.new_medicine_taking_item_meal_times;
      this.new_medicine_taking_items[
        i_new_item
      ].medicine_take_time_id = parseFloat(
        this.medicineTakeForm.controls.medicine_take_time_id.value
      );
    } else {
      this.medicine_taking_items.push(medicine_taking_items);
    }

    this.global.loadScript("assets/script/modal.js");
    this.isOpen = false;
    this.searchable = true;
  }

  setDataUpdateMedicineTakingItem() {
    this.submitted = true;

    if (this.medicineTakeForm.invalid) {
      return;
    }

    var new_medicine_taking_items = {
      medicine_taking_id: this.medicine_taking_id,
      medicine_id: this.medicineTakeForm.value.medicine_id.id
        ? this.medicineTakeForm.value.medicine_id.id
        : this.medicineTakeForm.value.medicine,
      amount_package: parseFloat(
        this.medicineTakeForm.controls.amount_package.value
      ),
      amount_total: this.medicineTakeForm.controls.amount_total.value,
      amount_take: parseFloat(this.medicineTakeForm.controls.amount_take.value),
      remark: this.medicineTakeForm.controls.remark.value,
      medicine_take_time_id: parseFloat(
        this.medicineTakeForm.controls.medicine_take_time_id.value
      ),
      amount_remain: this.medicineTakeForm.controls.amount_remain.value,
      medicine_package_id: this.medicineTakeForm.value.medicine_id
        .medicine_package_id
        ? this.medicineTakeForm.value.medicine_id.medicine_package_id
        : this.medicineTakeForm.value.medicine_package_id,
      medicine_unit_id: this.medicineTakeForm.value.medicine_id.medicine_unit_id
        ? this.medicineTakeForm.value.medicine_id.medicine_unit_id
        : this.medicineTakeForm.value.medicine_unit_id,
      new_medicine_taking_item_meal_times: [],
      id: this.medicineTakeForm.value.id
    };

    var meal_times = this.medicineTakeForm.controls.meal_times.value;
    var meal_times_arr = [];
    for (let i = 0; i < meal_times.length; i++) {
      const element = meal_times[i];
      if (element == true) {
        meal_times_arr.push(this.meal_times[i].id);
      }
    }

    for (let i = 0; i < meal_times_arr.length; i++) {
      const element = meal_times_arr[i];
      var obj = {
        meal_time_id: element
      };
      new_medicine_taking_items.new_medicine_taking_item_meal_times.push(obj);
    }

    const medicine_take_time_group_find = this.arr_meal_time_validate.filter(
      meal_time => meal_time < 3
    );
    if (
      this.medicineTakeForm.controls.medicine_take_time_groups.value != "" &&
      this.medicineTakeForm.controls.medicine_take_time_groups.value != null
    ) {
      new_medicine_taking_items["take_count"] = meal_times_arr.length;
      this.validate_meal_times.medicine_take_time_group = false;
      if (this.medicineTakeForm.controls.medicine_take_time_id.value == null) {
        this.validate_meal_times.medicine_take_time_group = true;
        return false;
      } else {
        this.validate_meal_times.medicine_take_time_group = false;
      }
    } else {
      if (
        medicine_take_time_group_find.length != 0 &&
        (this.medicineTakeForm.controls.medicine_take_time_id.value == null ||
          this.medicineTakeForm.controls.medicine_take_time_id.value == "null")
      ) {
        this.validate_meal_times.medicine_take_time_group = true;
        return false;
      } else {
        this.validate_meal_times.medicine_take_time_group = false;
      }
    }

    if (meal_times_arr.length == 0) {
      this.validate_meal_times.meal_times = true;
      return false;
    }

    swal({
      title: "ยืนยันการบันทึก",
      text: "คุณต้องการบันทึกข้อมูลใช่หรือไม่ ?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#36a3f7",
      cancelButtonColor: "#f4516c",
      confirmButtonText: "ใช่, บันทึกข้อมูล",
      cancelButtonText: "ยกเลิก"
    }).then(result => {
      if (result.value) {
        this.updateMedicineTakingItem(new_medicine_taking_items);
        this.medicine_taking_item_edit = false;
        this.global.loadScript("assets/script/modal.js");
        this.isOpen = false;
        this.searchable = true;
        this.validate_meal_times = {
          meal_times: false,
          medicine_take_time_group: false
        };
      }
    });
  }

  removeItem(index, medicine_taking_item_id) {
    swal({
      title: "ยืนยันการลบข้อมูล",
      text: "คุณต้องการบลบข้อมูลใช่หรือไม่ ?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#36a3f7",
      cancelButtonColor: "#f4516c",
      confirmButtonText: "ใช่, ลบข้อมูล",
      cancelButtonText: "ยกเลิก"
    }).then(result => {
      if (result.value) {
        if (medicine_taking_item_id) {
          this.MedicineTakingItemService.deleteMedicineTakingItem(
            medicine_taking_item_id
          ).subscribe(data => {
            swal({
              type: "success",
              title: "ลบข้อมูลเรียบร้อยแล้ว",
              showConfirmButton: false,
              timer: 1500
            });
            this.getMedicineTaking();
          });
        }
        this.medicine_taking_items.splice(index, 1);
        this.new_medicine_taking_items.splice(index, 1);
      }
    });
  }

  onSubmit() {
    var new_medicine_taking_items = this.new_medicine_taking_items;
    var medicine_taking = {
      medicine_taking: {
        medicine_taking_type_id: 1,
        visit_id: 2,
        date: this.global.getDateNowYMD(),
        patient_id: this.patient_id,
        description: "",
        is_active: true,
        new_medicine_taking_items
      }
    };
    swal({
      title: "ยืนยันการบันทึก",
      text: "คุณต้องการบันทึกข้อมูลใช่หรือไม่ ?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#36a3f7",
      cancelButtonColor: "#f4516c",
      confirmButtonText: "ใช่, บันทึกข้อมูล",
      cancelButtonText: "ยกเลิก"
    }).then(result => {
      if (result.value) {
        if (this.medicine_taking_id != null) {
          medicine_taking.medicine_taking.new_medicine_taking_items.forEach(
            element => {
              if (element.id) {
                this.updateMedicineTakingItem(element);
              } else {
                this.submitMedicineTakingItem(element);
              }
            }
          );
        } else {
          this.submitMedicineTaking(medicine_taking);
        }
      }
    });
  }

  submitMedicineTaking(medicine_taking) {
    this.MedicineTakingService.submitMedicineTaking(medicine_taking).subscribe(
      data => {
        swal({
          type: "success",
          title: "บันทึกข้อมูลเรียบร้อยแล้ว",
          showConfirmButton: false,
          timer: 1500
        });
        this.getMedicineTaking();
        this.setForm();
        this.medicine_taking_items = [];
        this.isOpen = true;
        this.searchable = true;
        this.arr_meal_time_validate = [];
        this.validate_meal_times = {
          meal_times: false,
          medicine_take_time_group: false
        };
      }
    );
  }

  submitMedicineTakingItem(medicine_taking_item) {
    this.MedicineTakingItemService.submitMedicineTakingItem(
      medicine_taking_item
    ).subscribe(data => {
      swal({
        type: "success",
        title: "บันทึกข้อมูลเรียบร้อยแล้ว",
        showConfirmButton: false,
        timer: 1500
      });
      this.getMedicineTaking();
      this.setForm();
      this.medicine_taking_items = [];
      this.new_medicine_taking_items = [];
      this.isOpen = true;
      this.searchable = true;
      this.arr_meal_time_validate = [];
      this.validate_meal_times = {
        meal_times: false,
        medicine_take_time_group: false
      };
    });
  }

  updateMedicineTakingItem(medicine_taking_item) {
    this.MedicineTakingItemService.updateMedicineTakingItem(
      medicine_taking_item
    ).subscribe(data => {
      swal({
        type: "success",
        title: "บันทึกข้อมูลเรียบร้อยแล้ว",
        showConfirmButton: false,
        timer: 1500
      });
      this.getMedicineTaking();
      this.setForm();
      this.medicine_taking_items = [];
      this.isOpen = true;
      this.searchable = true;
      this.arr_meal_time_validate = [];
      this.validate_meal_times = {
        meal_times: false,
        medicine_take_time_group: false
      };
    });
  }

  getMedicineTaking() {
    var params = {
      patient_id: this.patient_id,
      medicine_taking_type_id: 1,
      order: "id desc"
    };
    this.MedicineTakingService.getMedicineTakings(params).subscribe(data => {
      this.medicine_takings = data;
      this.medicine_takings.forEach(element => {
        var date = this.global.getDateNowYMD();
        if (element.date == date) {
          this.medicine_taking_id = element.id;
        }
        element.medicine_taking_items.forEach(medicine_taking_item => {
          medicine_taking_item.meal_time_arr =
            medicine_taking_item.medicine_taking_item_meal_times;
          if (medicine_taking_item.medicine_take_time) {
            medicine_taking_item.medicine_take_time_name =
              medicine_taking_item.medicine_take_time
                .medicine_take_time_group_name +
              " " +
              medicine_taking_item.medicine_take_time.name;
          }
          if (medicine_taking_item.medicine_taking_item_meal_times) {
            var ans = "";
            medicine_taking_item.medicine_taking_item_meal_times.forEach(
              medicine_taking_item_meal_time => {
                if (ans != "") {
                  ans += ", ";
                }
                ans += medicine_taking_item_meal_time.meal_time_name;

                medicine_taking_item.medicine_taking_item_meal_times = ans;
                medicine_taking_item.meal_times_name = ans;
              }
            );
          }
          medicine_taking_item.medicine_name =
            medicine_taking_item.medicine.generic_name +
            " " +
            medicine_taking_item.medicine.trade_name +
            " " +
            medicine_taking_item.medicine.dose +
            " " +
            medicine_taking_item.medicine.medicine_unit_name;

          if (element.date == date) {
            this.medicine_taking_items = element.medicine_taking_items;
          }
        });

        element.m_accordion_item_head =
          "m_accordion_1_item_" + element.id + "_head";
        element.m_accordion_item_body =
          "m_accordion_1_item_" + element.id + "_body";
        element.m_accordion_item_body_href =
          "#m_accordion_1_item_" + element.id + "_body";
        element.date = this.global.setDateFormatThaiLongMonth(element.date);
      });
    });
  }

  getMedicines() {
    var filters = {
      hospital_id: this.user.doctor.hospital_id
    };
    this.MedicineService.getMedicines(filters).subscribe(data => {
      this.medicines = data;
      this.medicines.forEach(element => {
        element.name =
          element.generic_name +
          " " +
          element.trade_name +
          " " +
          element.dose +
          " " +
          element.medicine_unit_name;
        var name = "";
        var stripedHtml = element.name.replace("<sup>", "^");
        name = stripedHtml;
        name = name.replace("</sup>", "");
        element.name = name;
      });
    });
  }

  getMedicineTakeTimes(medicine_take_time_group_id = "") {
    var filter = {};
    if (medicine_take_time_group_id != "") {
      filter["medicine_take_time_group_id"] = medicine_take_time_group_id;
      this.MedicineTakingService.getMedicineTakeTimes(filter).subscribe(
        data => {
          this.medicine_take_times = data;
        }
      );
    }
  }

  getMedicineTakeTimeGroups() {
    this.MedicineTakingService.getMedicineTakeTimeGroups().subscribe(data => {
      this.medicine_take_time_groups = data;
    });
  }

  getMealTimes() {
    this.MedicineTakingService.getMealTimes().subscribe(data => {
      this.meal_times = data;
    });
  }

  showMedicine() {
    this.medicine = this.medicineTakeForm.value.medicine_id;
    this.readonlyAmountPackage = false;
  }

  calAmountTotal() {
    // var amount_total = 0
    var amount_package = this.medicineTakeForm.controls.amount_package.value;
    // if (amount_package) {
    //   amount_total = parseFloat(amount_package) * this.medicine.dose
    // }

    this.medicineTakeForm.patchValue({
      amount_total: amount_package
    });
  }

  calAmountRemain() {
    if (this.medicine_taking_item) {
      var amount_remain = 0;
      var amount_total = 0;
      var amount_package_update = 0;
      var amount_total_update = 0;
      var amount_package = this.medicineTakeForm.controls.amount_package.value;

      if (amount_package == "" || amount_package == 0) {
        amount_remain = 0;
      } else if (amount_package > this.medicine_taking_item.amount_package) {
        amount_package_update =
          amount_package - this.medicine_taking_item.amount_package;
        amount_total_update = amount_package_update * this.medicine.dose;
        amount_remain =
          amount_total_update + this.medicine_taking_item.amount_remain;
      } else if (amount_package < this.medicine_taking_item.amount_package) {
        amount_package_update =
          this.medicine_taking_item.amount_package - amount_package;
        amount_total_update = amount_package_update * this.medicine.dose;
        amount_remain =
          this.medicine_taking_item.amount_remain - amount_total_update;
      } else {
        amount_remain = this.medicine_taking_item.amount_remain;
      }

      this.medicineTakeForm.patchValue({
        amount_remain: amount_remain
      });
    }
  }

  cancelAddItem() {
    this.medicine_taking_items = [];
  }

  uncheck(event) {
    this.medicineTakeForm.patchValue({
      medicine_take_time_id: null
    });
    if (
      this.medicineTakeForm.controls.medicine_take_time_groups.value == event
    ) {
      this.medicineTakeForm.patchValue({
        medicine_take_time_groups: "",
        medicine_take_time_id: null,
        medicine_take_time_group_id: ""
      });
      this.medicine_take_times = [];
    }
  }
}
