import {
  Component,
  OnInit,
  ViewEncapsulation,
  AfterViewInit,
  ViewChild
} from "@angular/core";
import { CarerService } from "../../../services/carer.service";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from "@angular/forms";
import { GlobalService } from "../../../services/global.service";
import { LevelOfEducationService } from "../../../services/level-of-education.service";
import swal from "sweetalert2";
import { ScriptLoaderService } from "src/app/_services/script-loader.service";
import { MustMatch } from "../../../_helpers/must-match.validator";
import { service_url } from "../../../services/service-url";
import { MatTableDataSource, MatSort, MatPaginator } from "@angular/material";
import { DatePipe } from "@angular/common";
import { UserService } from "../../../services/user.service";
import { GenderService } from "src/app/services/gender.service";
@Component({
  selector: "app-setting-carer",
  templateUrl: "./setting-carer.component.html",
  styleUrls: ["./setting-carer.component.scss"]
})
export class SettingCarerComponent implements OnInit {
  carers: MatTableDataSource<any>;
  filtersForm: FormGroup;
  carersForm: FormGroup;
  submitted = false;
  api_url = service_url.api_url;
  selectedFile: File = null;
  imageUrl: string = "url(./assets/images/user/user-male.png)";
  level_of_educations: Object;
  birth_date: any = new Date();
  validateIdCard: boolean = false;
  user: any;

  genderOptions: Array<any> = [];

  limit: number = 25;
  totalLength: number = 0;
  pageIndex: number = 0;
  pageLimit: number[] = [25, 50, 100];

  displayedColumns: string[] = [
    "index",
    "display_name",
    "username",
    "email",
    "phone",
    "action"
  ];
  @ViewChild(MatSort) sort: MatSort;
  // @ViewChild(MatPaginator) paginator: MatPaginator
  errors: any;
  constructor(
    private carerService: CarerService,
    private formBuilder: FormBuilder,
    private Global: GlobalService,
    private _script: ScriptLoaderService,
    private LevelOfEducationService: LevelOfEducationService,
    public datepipe: DatePipe,
    private UserService: UserService,
    private GenderService: GenderService
  ) {
    var sessions = JSON.parse(sessionStorage.getItem("sessions"));
    this.user = sessions.user;
  }

  ngOnInit() {
    this.setFiltersForm();
    this.getCarers();
    this.setForm();
    this.getGenders();
  }

  setFiltersForm() {
    this.filtersForm = this.formBuilder.group({
      keywords: ""
    });
  }

  getLevelOfEducations() {
    this.LevelOfEducationService.getLevelOfEducations().subscribe(data => {
      this.level_of_educations = data;
    });
  }
  onChangeBirthDate(value) {
    this.birth_date = value;
  }
  setForm() {
    this.birth_date = new Date();
    this.getLevelOfEducations();
    this.imageUrl = "url(./assets/images/user/user-male.png)";
    this.carersForm = this.formBuilder.group(
      {
        idcard: ["", [Validators.required, Validators.maxLength(13)]],
        email: ["", [Validators.required, Validators.email]],
        // username: ['', Validators.required],
        first_name: ["", Validators.required],
        last_name: ["", Validators.required],
        level_of_education_id: ["", Validators.required],
        phone: ["", Validators.required],
        birth_date: [new Date(), Validators.required],
        congenital_disease: ["", Validators.required],
        password: ["", [Validators.required, Validators.minLength(4)]],
        password_confirmation: ["", Validators.required],
        image: new FormControl(),
        gender_id: ["", Validators.required],
        id: ""
      },
      {
        validator: MustMatch("password", "password_confirmation")
      }
    );
  }

  showForm(carer = null) {
    this.submitted = false;
    this.getCarers();
    this.validateIdCard = true;
    if (carer != null) {
      if (carer.birth_date != null) {
        // var birth_date = this.Global.setDateNumberDMY(carer.birth_date)
        this.birth_date = new Date(carer["birth_date"]);
      }
      carer.birth_date = new Date(carer.birth_date);
      this.carersForm.patchValue(carer);

      this.carersForm.get("password").clearValidators();
      this.carersForm.get("password_confirmation").clearValidators();
      this.carersForm.get("password").updateValueAndValidity();
      this.carersForm.get("password_confirmation").updateValueAndValidity();

      this.imageUrl = carer.image_url;
    } else {
      this.setForm();
      this.selectedFile = null;
      this.imageUrl = "url(./assets/images/user/user-male.png)";
    }
  }

  changePage(event) {
    this.pageIndex = event.pageIndex;
    this.getCarers();
  }

  getCarers() {
    var filters = {
      datatable: true,
      limit: this.limit,
      offset: this.limit * this.pageIndex
    };
    if (this.filtersForm) {
      filters["keywords"] = this.filtersForm.value.keywords;
      if (this.filtersForm.value.keywords != "") {
        filters["offset"] = 0;
      }
    }

    if (this.user.role_id == 3 || this.user.role_id == 2) {
      filters["hospital_id"] = this.user.doctor.hospital_id;
    }

    this.carerService.getCarers(filters).subscribe(data => {
      let carers;
      carers = data["data"];
      this.totalLength = data["total"];
      let array = carers.map((item, index) => {
        item.index = index + 1;
        item.image_url =
          item.image_url != ""
            ? 'url("' + this.api_url + "/" + item.image_url + '")'
            : "url(./assets/images/user/user-male.png)";
        return item;
      });
      this.carers = new MatTableDataSource(array);
      this.carers.sort = this.sort;
      // this.carers.paginator = this.paginator
    });
  }

  handleFileInput(event) {
    this.selectedFile = event.target.files[0];
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = 'url("' + event.target.result + '")';
    };

    reader.readAsDataURL(this.selectedFile);
  }

  get f() {
    return this.carersForm.controls;
  }

  onSubmit() {
    var idcard = this.carersForm.controls.idcard.value;
    this.validateIdCard = this.Global.validateIdCard(idcard);

    this.submitted = true;

    if (this.carersForm.invalid || !this.validateIdCard) {
      return;
    }

    const fd = new FormData();
    swal({
      title: "ยืนยันการบันทึก",
      text: "คุณต้องการบันทึกข้อมูลใช่หรือไม่ ?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#36a3f7",
      cancelButtonColor: "#f4516c",
      confirmButtonText: "ใช่, บันทึกข้อมูล",
      cancelButtonText: "ยกเลิก"
    }).then(result => {
      if (result.value) {
        fd.append("carer[email]", this.carersForm.controls.email.value);
        fd.append("carer[username]", this.carersForm.controls.email.value);
        fd.append(
          "carer[first_name]",
          this.carersForm.controls.first_name.value
        );
        fd.append("carer[last_name]", this.carersForm.controls.last_name.value);
        fd.append(
          "carer[display_name]",
          this.carersForm.controls.first_name.value +
            " " +
            this.carersForm.controls.last_name.value
        );
        if (this.selectedFile != null) {
          fd.append("carer[image]", this.selectedFile, this.selectedFile.name);
        }
        fd.append("carer[idcard]", this.carersForm.controls.idcard.value);
        fd.append(
          "carer[level_of_education_id]",
          this.carersForm.controls.level_of_education_id.value
        );
        fd.append("carer[phone]", this.carersForm.controls.phone.value);
        fd.append("carer[gender_id]", this.carersForm.controls.gender_id.value);
        fd.append(
          "carer[birth_date]",
          this.datepipe.transform(this.birth_date, "yyyy-MM-dd")
        );
        fd.append(
          "carer[congenital_disease]",
          this.carersForm.controls.congenital_disease.value
        );
        fd.append("carer[is_verify]", "true");
        if (this.carersForm.controls.password.value != null) {
          fd.append(
            "carer[password]",
            this.carersForm.controls.password.value.trim()
          );
          fd.append(
            "carer[password_confirmation]",
            this.carersForm.controls.password_confirmation.value.trim()
          );
        }

        if (this.carersForm.controls.id) {
          var id = this.carersForm.controls.id.value;
        }

        this.carerService.submitCarer(fd, id).subscribe(
          res => {
            swal({
              type: "success",
              title: "บันทึกข้อมูลเรียบร้อยแล้ว",
              showConfirmButton: false,
              timer: 1500
            });
            this.getCarers();
            this.setForm();
            this.submitted = false;
            window.location.reload();
            this.Global.loadScript("assets/script/modal.js");
          },
          error => {
            this.errors = error.error;
            swal({
              type: "warning",
              title: "การบันทึกข้อมูลผิดพลาด",
              showConfirmButton: true
            });
          }
        );
      }
    });
  }

  delete(carer) {
    swal({
      title: "ยืนยันการลบข้อมูล",
      text: "คุณต้องการลบข้อมูลใช่หรือไม่ ?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#36a3f7",
      cancelButtonColor: "#f4516c",
      confirmButtonText: "ใช่, ลบข้อมูล",
      cancelButtonText: "ยกเลิก"
    }).then(result => {
      if (result.value) {
        this.UserService.delUser(carer.user_id).subscribe(res => {
          swal({
            type: "success",
            title: "ลบข้อมูลเรียบร้อยแล้ว",
            showConfirmButton: false,
            timer: 1500
          });
          this.getCarers();
        });
      }
    });
  }

  getGenders() {
    this.GenderService.getList().subscribe((results: Array<any>) => {
      this.genderOptions = results;
    });
  }
}
