import { Component, OnInit, ViewChild } from "@angular/core";
import { DoctorService } from "../../../services/doctor.service";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from "@angular/forms";
import { GlobalService } from "../../../services/global.service";
import swal from "sweetalert2";
import { ScriptLoaderService } from "src/app/_services/script-loader.service";
import { MustMatch } from "../../../_helpers/must-match.validator";
import { service_url } from "../../../services/service-url";
import { MatTableDataSource, MatSort, MatPaginator } from "@angular/material";
import { UserService } from "../../../services/user.service";
import { HospitalService } from "../../../services/hospital.service";
@Component({
  selector: "app-setting-doctor",
  templateUrl: "./setting-doctor.component.html",
  styleUrls: ["./setting-doctor.component.scss"]
})
export class SettingDoctorComponent implements OnInit {
  doctors: MatTableDataSource<any>;
  filtersForm: FormGroup;
  doctorsForm: FormGroup;
  submitted = false;
  api_url = service_url.api_url;
  selectedFile: File = null;
  imageUrl: string = "./assets/images/user/user-male.png";
  user: any;

  displayedColumns: string[] = [
    "index",
    "display_name",
    "hospital_name",
    "role_name",
    "email",
    "phone",
    "action"
  ];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  errors: any;
  validateIdCard: boolean = false;
  hospitals: any;

  constructor(
    private doctorService: DoctorService,
    private formBuilder: FormBuilder,
    private Global: GlobalService,
    private _script: ScriptLoaderService,
    private UserService: UserService,
    private HospitalService: HospitalService
  ) {
    var sessions = JSON.parse(sessionStorage.getItem("sessions"));
    this.user = sessions.user;
  }

  ngOnInit() {
    this.setFiltersForm();
    this.getDoctors();
    this.setForm();
    this.getHospital();
  }

  setFiltersForm() {
    this.filtersForm = this.formBuilder.group({
      keywords: "",
      hospital_id: null
    });
  }

  getDoctors() {
    var filters = {};

    if (this.filtersForm) {
      filters["keywords"] = this.filtersForm.value.keywords;
    }

    if (this.filtersForm.value.hospital_id != null) {
      filters["hospital_id"] = this.filtersForm.value.hospital_id;
    }
    if (this.user.role_id == 3) {
      filters["hospital_id"] = this.user.doctor.hospital_id;
    }
    this.doctorService.getDoctors(filters).subscribe(data => {
      let doctors;
      doctors = data;
      let array = doctors.map((item, index) => {
        item.index = index + 1;
        item.image_url =
          item.image_url != ""
            ? 'url("' + this.api_url + "/" + item.image_url + '")'
            : "url(./assets/images/user/user-male.png)";
        return item;
      });
      this.doctors = new MatTableDataSource(array);
      this.doctors.sort = this.sort;
      this.doctors.paginator = this.paginator;
    });
  }

  getHospital() {
    this.HospitalService.getHospitals().subscribe(data => {
      this.hospitals = data;
    });
  }

  setForm() {
    var sessions = JSON.parse(sessionStorage.getItem("sessions"));

    this.imageUrl = "url(./assets/images/user/user-male.png)";
    this.doctorsForm = this.formBuilder.group(
      {
        idcard: ["", [Validators.required, Validators.maxLength(13)]],
        first_name: ["", Validators.required],
        last_name: ["", Validators.required],
        professional_cert: "",
        phone: ["", Validators.required],
        workplace: "",
        hospital_id: ["", Validators.required],
        email: ["", [Validators.required, Validators.email]],
        // username: ['', Validators.required],
        role_id: 2,
        password: ["", [Validators.required, Validators.minLength(4)]],
        password_confirmation: ["", Validators.required],
        image: new FormControl(),
        id: ""
      },
      {
        validator: MustMatch("password", "password_confirmation")
      }
    );
  }

  showForm(doctor = null) {
    this.submitted = false;
    this.getDoctors();
    this.validateIdCard = true;
    if (this.user.role_id != 1) {
      this.doctorsForm.get("hospital_id").clearValidators();
      this.doctorsForm.get("hospital_id").updateValueAndValidity();
    }
    if (doctor != null) {
      this.doctorsForm.patchValue(doctor);

      this.doctorsForm.get("password").clearValidators();
      this.doctorsForm.get("password_confirmation").clearValidators();
      this.doctorsForm.get("password").updateValueAndValidity();
      this.doctorsForm.get("password_confirmation").updateValueAndValidity();

      this.imageUrl = doctor.image_url;
    } else {
      this.setForm();
      this.selectedFile = null;
      this.imageUrl = "url(./assets/images/user/user-male.png)";
    }
  }

  get f() {
    return this.doctorsForm.controls;
  }

  onSubmit() {
    var idcard = this.doctorsForm.controls.idcard.value;
    this.validateIdCard = this.Global.validateIdCard(idcard);

    this.submitted = true;
    if (this.doctorsForm.invalid || !this.validateIdCard) {
      return;
    }
    const fd = new FormData();
    swal({
      title: "ยืนยันการบันทึก",
      text: "คุณต้องการบันทึกข้อมูลใช่หรือไม่ ?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#36a3f7",
      cancelButtonColor: "#f4516c",
      confirmButtonText: "ใช่, บันทึกข้อมูล",
      cancelButtonText: "ยกเลิก"
    }).then(result => {
      if (result.value) {
        if (this.selectedFile != null) {
          fd.append("doctor[image]", this.selectedFile, this.selectedFile.name);
        }
        fd.append("doctor[email]", this.doctorsForm.controls.email.value);
        fd.append("doctor[username]", this.doctorsForm.controls.email.value);
        fd.append(
          "doctor[first_name]",
          this.doctorsForm.controls.first_name.value
        );
        fd.append(
          "doctor[last_name]",
          this.doctorsForm.controls.last_name.value
        );
        fd.append(
          "doctor[display_name]",
          this.doctorsForm.controls.first_name.value +
            " " +
            this.doctorsForm.controls.last_name.value
        );
        fd.append(
          "doctor[professional_cert]",
          this.doctorsForm.controls.professional_cert.value
        );
        fd.append("doctor[idcard]", this.doctorsForm.controls.idcard.value);
        fd.append("doctor[phone]", this.doctorsForm.controls.phone.value);
        fd.append(
          "doctor[workplace]",
          this.doctorsForm.controls.workplace.value
        );
        fd.append("doctor[is_verify]", "true");
        if (this.doctorsForm.controls.password.value != null) {
          fd.append(
            "doctor[password]",
            this.doctorsForm.controls.password.value.trim()
          );
          fd.append(
            "doctor[password_confirmation]",
            this.doctorsForm.controls.password_confirmation.value.trim()
          );
        }
        fd.append(
          "doctor[hospital_id]",
          this.user.doctor
            ? this.user.doctor.hospital_id
            : this.doctorsForm.controls.hospital_id.value
        );
        fd.append("doctor[role_id]", this.doctorsForm.controls.role_id.value);

        var doctor_id = "";
        if (this.doctorsForm.controls.id) {
          doctor_id = this.doctorsForm.controls.id.value;
        }
        this.doctorService.submitDoctor(fd, doctor_id).subscribe(
          res => {
            swal({
              type: "success",
              title: "บันทึกข้อมูลเรียบร้อยแล้ว",
              showConfirmButton: false,
              timer: 1500
            });
            this.getDoctors();
            this.setForm();
            this.submitted = false;
            this.Global.loadScript("assets/script/modal.js");
          },
          error => {
            this.errors = error.error;
            swal({
              type: "warning",
              title: "การบันทึกข้อมูลผิดพลาด",
              showConfirmButton: true
            });
          }
        );
      }
    });
  }

  onDelete(doctor) {
    swal({
      title: "ยืนยันการลบข้อมูล",
      text: "คุณต้องการลบข้อมูลใช่หรือไม่ ?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#36a3f7",
      cancelButtonColor: "#f4516c",
      confirmButtonText: "ใช่, ลบข้อมูล",
      cancelButtonText: "ยกเลิก"
    }).then(result => {
      if (result.value) {
        this.UserService.delUser(doctor.user_id).subscribe(res => {
          swal({
            type: "success",
            title: "ลบข้อมูลเรียบร้อยแล้ว",
            showConfirmButton: false,
            timer: 1500
          });
          this.getDoctors();

          this.submitted = false;
          this.Global.loadScript("assets/script/modal.js");
        });
      }
    });
  }

  handleFileInput(event) {
    this.selectedFile = event.target.files[0];
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = 'url("' + event.target.result + '")';
    };
    reader.readAsDataURL(this.selectedFile);
  }
}
