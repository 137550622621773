import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalService } from "../../../services/global.service";
import { PatientService } from "../../../services/patient.service";
import { service_url } from "../../../services/service-url";
import { PatientTestsService } from "../../../services/patient-tests.service";
import { Router } from '@angular/router';
import { MatTableDataSource, MatSort, MatPaginator } from "@angular/material";
import { BaseChartDirective } from 'ng2-charts';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
@Component({
  selector: 'app-patient-dashboard',
  templateUrl: './patient-dashboard.component.html',
  styleUrls: ['./patient-dashboard.component.scss']
})
export class PatientDashboardComponent implements OnInit {
  _onLangChange: any;
  currentLang: string;

  month: boolean = true
  year: boolean = false
  wearable: any
  wearable_pie: any
  month_cur: any = new Date().getMonth();
  title_month: any
  patient: any = {};
  api_url = service_url.api_url
  patient_id: any = sessionStorage.getItem('patient_id')
  chartDataTest1: any = []
  chartLabelsTest1: any = [];
  chartDataFrequently: any = []
  chartLabelTest2: any = []
  months: any = this.global.month_thai_arr
  years: any = this.global.years_list

  chartOptions = {
    responsive: true,
    elements: {
      line: {
        tension: 0.1
      },
    },
    legend: {
      position: "bottom",
      labels: {
        padding: 40,
        fontColor: '#575962',
        fontFamily: 'Kanit',
        fontSize: 14,
        fontStyle: '500'
      }
    },
    scales: {
      xAxes: [
        {
          ticks: {
            fontSize: 14,
            fontFamily: 'Kanit',
            fontStyle: '500'
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            fontSize: 14,
            fontFamily: 'Kanit',
            fontStyle: '500',
            beginAtZero: true,
            steps: 5,
            max: 30,
          }
        }
      ]
    }
  };

  chartDataWearable = [
    { data: [12, 16, 14, 13], label: 'Active', fill: false },
    { data: [12, 10, 8, 11], label: 'Inactive', fill: false }
  ];

  chartDataWearableDonut = [
    { data: [11, 13] },
  ];

  chartLabelsWearable = ['ครั้งที่ 1', 'ครั้งที่ 2', 'ครั้งที่ 3', 'ครั้งที่ 4'];
  chartLabelsWearableDonut = ['Active', 'Inactive'];


  patient_test_1: MatTableDataSource<any>;
  test1DisplayedColumns: string[] = ['index', 'created_date', 'total_score', 'interpret_symptoms', 'display_name']

  @ViewChild(MatSort) sort: MatSort
  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild(BaseChartDirective) public _chart: BaseChartDirective
  month_list: any;
  year_list: any = new Date().getFullYear() + 543;
  filter_test1: any;
  month_filter_test1: any;
  year_filter: any;

  constructor(
    private global: GlobalService,
    private PatientService: PatientService,
    private PatientTestsService: PatientTestsService,
    private router: Router,
    private translate: TranslateService,
  ) {
    this._onLangChange = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLang = this.translate.currentLang
      // this.getPatientTests1(this.filter_test1, this.month_filter_test1)
    });
  }

  ngOnInit() {
    this.getPatientTests1('month', this.month_cur + 1)
    this.getPatient()
    this.title_month = this.global.setMonthThaiShort()
    this.month_list = this.title_month
  }

  ngOnDestroy() {
    this._onLangChange.unsubscribe();
  }

  getMonth(month) { // current date 2018-02-26
    var mm = month

    var m
    if (mm < 10) {
      m = '0' + mm
    }
    else {
      m = mm
    }

    return m
  }

  filter(param = '', month = '') {
    let year = this.year_list-543
    
    var filters = {
      test_id: 1,
      order: 'updated_at asc',
      patient_id: this.patient_id
    }

    if (param == 'year') {
      this.year = true
      this.month = false
      filters['begin_date'] = year + '-01-01'
      filters['end_date'] = year+ '-12-31'
    }
    else if (param == 'month') {
      this.month = true
      this.year = false
      if (month == null) {
        this.month_list = this.title_month
        month = this.month_cur + 1
      }

      var m = this.getMonth(month)
      filters['monyear'] = m + '' + year
    }

    return filters
  }

  getPatient() {
    this.PatientService.showPatient(this.patient_id).subscribe(data => {

      this.patient = data
      this.patient.phone = (this.patient.phone == null ? '-' : this.patient.phone)
      this.patient.congenital_disease = (this.patient.congenital_disease == '' ? '-' : this.patient.congenital_disease)
      this.patient.image_url = (this.patient.image_url != '' ? this.api_url + '/' + this.patient.image_url : './assets/images/user/user-male.png')
      this.patient.image_url = 'url(' + this.patient.image_url + ')'
      this.patient.birth_date = this.global.setDateFormatThaiLongMonth(this.patient.birth_date)
      this.patient.carer_patients.forEach(carer_patient => {
        switch (carer_patient.carer_patient_status_id) {
          case 1: carer_patient.carer_patient_status_name = 'ผู้ดูแลหลัก'; break;
          case 2: carer_patient.carer_patient_status_name = 'ผู้ดูแลรอง'; break;
        }
        carer_patient.carer.image_url = (carer_patient.carer.image_url != '' ? this.api_url + carer_patient.carer.image_url : './assets/images/user/user-male.png')
        carer_patient.carer.image_url = 'url(' + carer_patient.carer.image_url + ')'

      });
      this.patient.doctor_patients.forEach(doctor_patient => {
        switch (doctor_patient.doctor_patient_status_id) {
          case 1: doctor_patient.doctor_patient_status_name = 'ผู้ดูแลหลัก'; break;
          case 2: doctor_patient.doctor_patient_status_name = 'ผู้ดูแลรอง'; break;
        }
        doctor_patient.doctor.image_url = (doctor_patient.doctor.image_url != '' ? this.api_url + doctor_patient.doctor.image_url : './assets/images/user/user-male.png')
        doctor_patient.doctor.image_url = 'url(' + doctor_patient.doctor.image_url + ')'
      });
    })
  }

  getPatientTests1(param,month=null) {
    this.filter_test1 = param
    this.month_filter_test1 = month
    let filters = this.filter(param,month)

    this.chartLabelsTest1 = []
    var chartLabelsTest1 = []
    this.chartDataTest1 = [
      { data: [], label: 'คะแนน', fill: false }
    ];

    this.PatientTestsService.getPatientTests(filters).subscribe(data => {
      let patient_test_1
      patient_test_1 = data
      let array = patient_test_1.map((element, index) => {
        element.index = index + 1
        if (element.total_score >= 0 && element.total_score <= 13) {
          element.score_level = 1
          element.interpret_symptoms = 'อาการของภาวะสมองเสื่อมส่งผลให้ความสามารถในการทำคำสั่งต่าง ๆ ที่ง่าย ๆ ไม่สำเร็จและบกพร่องแทบทั้งหมด'
        }
        else if (element.total_score >= 14 && element.total_score <= 25) {
          element.score_level = 2
          element.interpret_symptoms = 'อาการของภาวะสมองเสื่อมส่งผลให้ความสามารถในการทำคำสั่งต่าง ๆ ที่ง่าย ๆ ช้าลงมากและบกพร่องเป็นส่วนใหญ่ ต้องอาศัยการกระตุ้นอย่างมาก'
        }
        else if (element.total_score >= 26 && element.total_score <= 30) {
          element.score_level = 3
          element.interpret_symptoms = 'อาการของภาวะสมองเสื่อมส่งผลทำให้การทำตามคำสั่งต่าง ๆ ง่าย ๆ ช้าลง และเริ่มมีการบกพร่องบ้างแล้ว'
        }
        // element.created_date = this.global.setDateFormatThaiLongMonth(element.created_date)
        element.created_date = this.global.setDateFormatShort(element.created_date, this.currentLang)
        element.display_name = element.user.display_name

        chartLabelsTest1.push(element.created_date)
        this.chartDataTest1[0].data.push(element.total_score)
        this.refreshChart()

        return element
      })
      this.chartLabelsTest1 = chartLabelsTest1
      this.patient_test_1 = new MatTableDataSource(array)
      this.patient_test_1.sort = this.sort
      this.patient_test_1.paginator = this.paginator
    })

  }

  detailPage(test) {
    if (test.test_id == 1) {
      this.router.navigate(['/patient/test1-detail'], { queryParams: { test: test.id } });
    }
    else if (test.test_id == 2) {
      this.router.navigate(['/patient/test2-detail'], { queryParams: { test: test.id } });
    }
  }

  refreshChart() {
    setTimeout(() => {
      this._chart.chart.update();
    }, 200)
  }


}
