import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { service_url } from "./service-url";


@Injectable({
  providedIn: 'root'
})
export class PatientPostReplyService {
  api_url: any
  options = {}

  constructor(private http: HttpClient) {
    this.api_url = service_url.api_url
  }

  getPostReplies() {
    return this.http.get(this.api_url + "/post_replies")
  }

  showPostReply(post_reply_id) {
    return this.http.get(this.api_url + '/post_replies/' + post_reply_id)
  }

  submitPostReply(params, post_reply_id = '') {

    if (post_reply_id != '') {
      return this.http.put(this.api_url + '/post_replies/' + post_reply_id, params)
    }
    else {
      return this.http.post(this.api_url + '/post_replies', params)
    }
  }

  deletePostReply(post_reply_id) {
    return this.http.delete(this.api_url + '/post_replies/' + post_reply_id)
  }
}
