import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { GlobalService } from "../../../services/global.service";
import { PatientTestsService } from "../../../services/patient-tests.service";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
  ValidatorFn,
} from "@angular/forms";
import { MatTableDataSource, MatSort, MatPaginator } from "@angular/material";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-patient-test",
  templateUrl: "./patient-test.component.html",
  styleUrls: ["./patient-test.component.scss"],
})
export class PatientTestComponent implements OnInit {
  frequency: FormGroup;
  submitted = false;
  patient_id: any = sessionStorage.getItem("patient_id");
  patient_test_1: MatTableDataSource<any>;
  test1DisplayedColumns: string[] = [
    "index",
    "created_date",
    "total_score",
    "interpret_symptoms",
    "display_name",
  ];
  patient_test_2: MatTableDataSource<any>;
  test2DisplayedColumns: string[] = [
    "index",
    "created_date",
    "total_score_frequency",
    "total_score_effect",
    "display_name",
  ];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  months: any = this.global.month_thai_arr;
  month_list: any = null;
  title_month: any;
  month_cur: any = new Date().getMonth();
  test_id: any;
  range = { start: null, end: null };

  constructor(
    private router: Router,
    private global: GlobalService,
    private patientTestsService: PatientTestsService,
    private formBuilder: FormBuilder,
    private datepipe: DatePipe
  ) {}

  ngOnInit() {
    this.getPatientTests(1);
    this.setForm();
    // this.title_month = this.global.setMonthThaiShort()
    // this.month_list = this.title_month
  }

  getMonth(month) {
    // current date 2018-02-26
    var mm = month;

    var m;
    if (mm < 10) {
      m = "0" + mm;
    } else {
      m = mm;
    }

    return m;
  }

  setForm() {
    this.frequency = this.formBuilder.group({
      frequency: ["", Validators.required],
    });
  }

  showForm(frequency = "") {
    this.submitted = false;
    if (frequency != "") {
      this.frequency = this.formBuilder.group({
        frequency: [frequency["frequency"], Validators.required],
        id: frequency["id"],
      });
    } else {
      this.setForm();
    }
  }
  get f() {
    return this.frequency.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.frequency.invalid) {
      return;
    }
  }

  getPatientTests(test_id) {
    this.test_id = test_id;
    var year = new Date().getFullYear();

    var filters = {
      test_id: test_id,
      order: "updated_at desc",
      patient_id: this.patient_id,
    };

    if (this.month_list != null) {
      var m = this.getMonth(this.month_list.id);
      filters["monyear"] = m + "" + year;
    }

    const range_start = this.datepipe.transform(this.range.start, "yyyy-MM-dd");
    const range_end = this.datepipe.transform(this.range.end, "yyyy-MM-dd");

    if (range_start) {
      filters["begin_date"] = range_start;
    }

    if (range_end) {
      filters["end_date"] = range_end;
    }

    this.patientTestsService.getPatientTests(filters).subscribe((data) => {
      let patient_test_1;
      patient_test_1 = data;
      let array1 = patient_test_1.map((element, index) => {
        element.index = index + 1;

        if (element.total_score >= 0 && element.total_score <= 13) {
          element.score_level = 1;
          element.interpret_symptoms =
            "อาการของภาวะสมองเสื่อมส่งผลให้ความสามารถในการทำคำสั่งต่าง ๆ ที่ง่าย ๆ ไม่สำเร็จและบกพร่องแทบทั้งหมด";
        } else if (element.total_score >= 14 && element.total_score <= 25) {
          element.score_level = 2;
          element.interpret_symptoms =
            "อาการของภาวะสมองเสื่อมส่งผลให้ความสามารถในการทำคำสั่งต่าง ๆ ที่ง่าย ๆ ช้าลงมากและบกพร่องเป็นส่วนใหญ่ ต้องอาศัยการกระตุ้นอย่างมาก";
        } else if (element.total_score >= 26 && element.total_score <= 30) {
          element.score_level = 3;
          element.interpret_symptoms =
            "อาการของภาวะสมองเสื่อมส่งผลทำให้การทำตามคำสั่งต่าง ๆ ง่าย ๆ ช้าลง และเริ่มมีการบกพร่องบ้างแล้ว";
        }
        element.created_date = this.global.setDateFormatThaiLongMonth(
          element.created_date
        );
        element.display_name = element.user.display_name;

        return element;
      });
      this.patient_test_1 = new MatTableDataSource(array1);
      this.patient_test_1.sort = this.sort;
      this.patient_test_1.paginator = this.paginator;

      let patient_test_2;
      patient_test_2 = data;
      let array2 = patient_test_2.map((element, index) => {
        element.index = index + 1;
        var score_frequency = [];
        var score_effect = [];

        element.patient_test_values.forEach((patient_test_value, i) => {
          if (patient_test_value.has_sub_value != false) {
            if (patient_test_value.patient_test_sub_values[0]) {
              score_frequency.push(
                patient_test_value.patient_test_sub_values[0].value
              );
            }
            if (patient_test_value.patient_test_sub_values[1]) {
              score_effect.push(
                patient_test_value.patient_test_sub_values[1].value
              );
            }
          } else {
            score_frequency.push(0);
            score_effect.push(0);
          }
        });
        const total_score_effect = score_effect.reduce(
          (total_score_effect, current) => total_score_effect + current,
          0
        );
        const total_score_frequency = score_frequency.reduce(
          (total_score_frequency, current) => total_score_frequency + current,
          0
        );
        element.total_score_effect = total_score_effect;
        element.total_score_frequency = total_score_frequency;
        element.display_name = element.user.display_name;

        return element;
      });
      this.patient_test_2 = new MatTableDataSource(array2);
      this.patient_test_2.sort = this.sort;
      this.patient_test_2.paginator = this.paginator;
    });
  }

  detailPage(test) {
    if (test.test_id == 1) {
      this.router.navigate(["/patient/test1-detail"], {
        queryParams: { test: test.id },
      });
    } else if (test.test_id == 2) {
      this.router.navigate(["/patient/test2-detail"], {
        queryParams: { test: test.id },
      });
    }
  }

  rangeChange(range) {
    this.getPatientTests(this.test_id);
  }
}
