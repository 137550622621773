import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PatientsRoutingModule } from './patients-routing.module';
import { LayoutsModule } from '../../layouts/layouts.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    PatientsRoutingModule,
    LayoutsModule
  ]
})
export class PatientsModule { }
