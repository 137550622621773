import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { service_url } from "./service-url";

@Injectable({
  providedIn: "root"
})
export class HospitalService {
  api_url: any;
  constructor(private http: HttpClient) {
    this.api_url = service_url.api_url;
  }

  getHospitals(filters = {}) {
    let params = new HttpParams();
    Object.keys(filters).forEach(function(key) {
      params = params.append(key, filters[key]);
    });
    return this.http.get(this.api_url + "/hospitals", { params: params });
  }

  showHospital(hospital_id) {
    return this.http.get(this.api_url + "/hospitals/" + hospital_id);
  }

  submitHospital(params, id = "") {
    if (id != "") {
      return this.http.put(this.api_url + "/hospitals/" + id, params);
    } else {
      return this.http.post(this.api_url + "/hospitals", params);
    }
  }

  deleteHospital(hospital_id) {
    return this.http.delete(this.api_url + "/hospitals/" + hospital_id);
  }
}
