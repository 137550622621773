import { Component, OnInit, ViewEncapsulation, HostListener } from '@angular/core';


@Component({
  selector: "app-scroll-top",
  templateUrl: "./scroll-top.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class ScrollTopComponent implements OnInit {
  isShown: boolean = false;


  constructor() {

  }
  ngOnInit() {

  }

  @HostListener('window:scroll', ['$event']) onWindowScroll($event) {
    if (window.pageYOffset <= 300) {

      this.isShown = false
    } else {

      this.isShown = true
    }
  }

  scrollToTop() {
    $("html, body").animate({ scrollTop: 0 }, 700);
    return false;
  }
}