import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { PatientTestsService } from "src/app/services/patient-tests.service";
import { GlobalService } from "../../../services/global.service";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";

@Component({
  selector: "app-patient-test2-detail",
  templateUrl: "./patient-test2-detail.component.html",
  styleUrls: ["./patient-test2-detail.component.scss"],
})
export class PatientTest2DetailComponent implements OnInit {
  _onLangChange: any;
  currentLang: string;

  patient_tests_id: any;
  patient_tests: any = {};
  form2: any;
  radarChartData: any;
  dataFrequency: any = [];
  dataEffect: any = [];
  orderDesc: boolean = false;

  radarChartOptions = {
    legend: {
      position: "bottom",
      labels: {
        padding: 40,
        fontColor: "#575962",
        fontFamily: "Kanit",
        fontSize: 14,
        fontStyle: "500",
      },
    },
    scale: {
      pointLabels: {
        fontColor: "#575962",
        fontFamily: "Kanit",
        fontSize: 14,
      },
      ticks: {
        fontColor: "#575962",
        fontFamily: "Kanit",
        fontSize: 14,
        fontStyle: "500",
        beginAtZero: true,
        // steps: 1,
        // stepValue: 1,
        stepSize: 1,
        max: 5,
      },
    },
  };

  public radarChartColors: Array<any> = [
    {
      // first color
      backgroundColor: "rgba(21,133,209,0.5)",
      borderColor: "rgba(21,133,209,0.5)",
      pointBackgroundColor: "rgba(21,133,209,0.5)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(21,133,209,0.5)",
    },
    {
      // second color
      backgroundColor: "rgba(	236, 0, 50,0.5)",
      borderColor: "rgba(	236, 0, 50,0.5)",
      pointBackgroundColor: "rgba(	236, 0, 50,0.5)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(	236, 0, 50,0.5)",
    },
  ];

  radarChartLabels = [
    "Delusion",
    "Hallucination",
    "Affect regulation",
    "Anxious and distress",
    "Apathy",
    "Disinhibition",
    "Stereotypy",
    "Eating problem",
    "Sleep problem",
  ];
  sort: string;

  constructor(
    private route: ActivatedRoute,
    private patientTestsService: PatientTestsService,
    private global: GlobalService
  ) {
    this.route.queryParams.subscribe((params) => {
      this.patient_tests_id = params.test;
    });

    this.radarChartData = [
      { data: [], label: "ความถี่" },
      { data: [], label: "ผลกระทบ" },
    ];
  }

  ngOnInit() {
    this.showDetail();
    this.getForm();
  }

  getForm() {
    this.patientTestsService.getTest2().subscribe((data) => {
      this.form2 = data;
      // this.radarChartLabels = this.form2.question
    });
  }

  showDetail(sort = "frequency") {
    this.patientTestsService
      .showPatientTests(this.patient_tests_id)
      .subscribe((data) => {
        let patient_tests;
        patient_tests = data;
        this.patientTestsService.getTest2().subscribe((form2) => {
          this.form2 = form2;
          this.patient_tests.patient_test_values = [];
          var patient_test_value_null = [];
          for (let i = 0; i < patient_tests.patient_test_values.length; i++) {
            const element = patient_tests.patient_test_values[i];
            element.question = this.form2.question[i];

            if (element.has_sub_value == false) {
              element.answer = "ไม่มี";
              element.frequency_value = 0;
              element.effect_value = 0;
            } else {
              element.answer = "มี";
              element.frequency =
                this.form2.frequency[element.patient_test_sub_values[0].value];
              element.effect =
                this.form2.effect[element.patient_test_sub_values[1].value];
              element.frequency_value =
                element.patient_test_sub_values[0].value;
              element.effect_value = element.patient_test_sub_values[1].value;
            }

            var patient_test_values = {
              question: element.question,
              answer: element.answer,
              frequency: parseInt(element.frequency),
              effect: parseInt(element.effect),
              has_sub_value: element.has_sub_value == true ? true : false,
            };

            if (patient_test_values.has_sub_value == true) {
              this.patient_tests.patient_test_values.push(patient_test_values);
            } else {
              patient_test_value_null.push(patient_test_values);
            }

            this.dataFrequency.push(element.frequency_value);
            this.dataEffect.push(element.effect_value);
          }
          for (let index = 0; index < patient_test_value_null.length; index++) {
            this.patient_tests.patient_test_values.push(
              patient_test_value_null[index]
            );
          }

          this.radarChartData = [
            { data: this.dataFrequency, label: "ความถี่" },
            { data: this.dataEffect, label: "ผลกระทบ" },
          ];

          if (this.sort == sort) {
            this.orderDesc = !this.orderDesc;
          } else {
            this.orderDesc = true;
          }
          this.sort = sort;
          this.patient_tests.patient_test_values.sort((a, b) => {
            if (this.orderDesc) {
              return b[sort] - a[sort];
            } else {
              return a[sort] - b[sort];
            }
          });
        });

        this.patient_tests.user = data["user"].display_name;
        this.patient_tests.test_id = data["test_id"];
        this.patient_tests.patient_display_name = data["patient_display_name"];
        this.patient_tests.created_date =
          this.global.setDateFormatThaiLongMonth(patient_tests.created_date);
      });
  }
}
