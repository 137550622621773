import { Component, OnInit } from '@angular/core';
import { UserService } from "../../services/user.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalService } from "../../services/global.service";
import swal from 'sweetalert2'
import { service_url } from "../../services/service-url";
import { MustMatch } from '../../_helpers/must-match.validator';
@Component({
  selector: 'app-approve-user',
  templateUrl: './approve-user.component.html',
  styleUrls: ['./approve-user.component.scss']
})
export class ApproveUserComponent implements OnInit {
  users: any;
  userForm: FormGroup;
  submitted = false;
  api_url = service_url.api_url
  selectedFile: File = null;
  imageUrl: string = './assets/images/user/user-male.png';
  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    private Global: GlobalService,
  ) { }

  ngOnInit() {
    this.getUsers()
    this.setForm()
  }

  getUsers() {
    this.userService.getUsers().subscribe(data => {
      this.users = data
    })
  }

  setForm() {
    this.userForm = this.formBuilder.group({
      display_name: ['', Validators.required],
      role_id: 3,
      // email: ['', [Validators.required, Validators.email]],
      username: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(4)]],
      password_confirmation: ['', Validators.required],
      image: null,
    },
      {
        validator: MustMatch('password', 'password_confirmation')
      });
  }

  showForm(doctor = null) {

    this.submitted = false;
    this.getUsers()
    this.setForm()
    this.selectedFile = null;
    this.imageUrl = './assets/images/user/user-male.png';
  }

  get f() { return this.userForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.userForm.invalid) {
      return;
    }

  }

}
