import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { service_url } from "./service-url";
@Injectable({
  providedIn: 'root'
})
export class MedicineTakingItemService {

  api_url: any
  constructor(private http: HttpClient) {
    this.api_url = service_url.api_url
  }

  submitMedicineTakingItem(params) {
    return this.http.post(this.api_url + '/medicine_taking_items', { medicine_taking_item: params })
  }

  updateMedicineTakingItem(params) {
    return this.http.put(this.api_url + '/medicine_taking_items/' + params.id, { medicine_taking_item: params })
  }

  deleteMedicineTakingItem(medicine_taking_item_id) {
    return this.http.delete(this.api_url + '/medicine_taking_items/' + medicine_taking_item_id)
  }

}
