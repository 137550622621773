import { Component, ViewChild, OnInit } from '@angular/core';
import { GlobalService } from "../services/global.service";
@Component({
  selector: 'app-components',
  templateUrl: './components.component.html',
  styleUrls: ['./components.component.scss']
})
export class ComponentsComponent implements OnInit {

  constructor(
    private global: GlobalService
  ) { }

  ngOnInit() {
    this.global.scrollToTop()
  }

}
