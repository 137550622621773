import {
  Component,
  ComponentFactoryResolver,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthenService } from "../services/authen.service";
import { AlertComponent } from "../_directives/alert.component";
import { AlertService } from "../_services";
import swal from "sweetalert2";
import { ScriptLoaderService } from "../_services/script-loader.service";
import { Helpers } from "../helpers";
import { GlobalService } from "../services/global.service";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";

declare let $: any;
declare let mUtil: any;
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
  model: any;
  loading = false;
  returnUrl: string;

  @ViewChild("alertSignin", { read: ViewContainerRef })
  alertSignin: ViewContainerRef;
  @ViewChild("alertSignup", { read: ViewContainerRef })
  alertSignup: ViewContainerRef;
  @ViewChild("alertForgotPass", { read: ViewContainerRef })
  alertForgotPass: ViewContainerRef;

  constructor(
    private authenService: AuthenService,
    private router: Router,
    private cfr: ComponentFactoryResolver,
    private _alertService: AlertService,
    private _script: ScriptLoaderService,
    private global: GlobalService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.model = {};
    this.model.remember = true;
    // get return url from route parameters or default to '/'
  }

  signin() {
    this.loading = true;
    let obj = {
      username: this.model.username,
      password: this.model.password
    };

    this.authenService.authen(obj).subscribe(
      data => {
        if (data["user"]["role_id"] != 4) {
          sessionStorage.setItem("sessions", JSON.stringify(data));
          this.router.navigate(["/patient"]);
        } else {
          this.showAlert("alertSignin");
          this._alertService.error(
            this.translate.instant("LOGIN_PAGE.RESPONSE_FAIL")
          );
          this.loading = false;
        }
      },
      err => {
        this.showAlert("alertSignin");
        this._alertService.error(
          this.translate.instant("LOGIN_PAGE.RESPONSE_FAIL")
        );
        this.loading = false;
      }
    );
  }

  showAlert(target) {
    this[target].clear();
    let factory = this.cfr.resolveComponentFactory(AlertComponent);
    let ref = this[target].createComponent(factory);
    ref.changeDetectorRef.detectChanges();
  }

  handleSignInFormSubmit() {
    $("#m_login_signin_submit").click(e => {
      let form = $(e.target).closest("form");
      form.validate({
        rules: {
          username: {
            required: true
          },
          password: {
            required: true
          }
        }
      });
      if (!form.valid()) {
        e.preventDefault();
        return;
      }
    });
  }
}
