import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { service_url } from "./service-url";

@Injectable({
  providedIn: 'root'
})
export class LevelOfEducationService {
  api_url: any
  constructor(private http: HttpClient) {
    this.api_url = service_url.api_url
  }

  getLevelOfEducations() {
    return this.http.get(this.api_url + '/level_of_educations')
  }
}
