import { Injectable } from '@angular/core';
import { service_url } from './service-url';
import { HttpParams, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GenderService {
  api_url: any
  constructor(private http: HttpClient) {
    this.api_url = service_url.api_url
  }

  getList(filters = {}) {
    let params = new HttpParams();
    Object.keys(filters).forEach(function (key) {
      params = params.append(key, filters[key]);
    });
    return this.http.get(this.api_url + '/genders', { params: params })
  }

  show(id) {
    return this.http.get(this.api_url + '/genders/' + id)
  }

  submit(params, id = '') {


    if (id != '') {
      return this.http.put(this.api_url + '/genders/' + id, params)
    }
    else {
      return this.http.post(this.api_url + '/genders', params)
    }
  }

  delete(id) {
    return this.http.delete(this.api_url + '/genders/' + id)
  }
}
