import { Component, OnInit } from "@angular/core";
import { GlobalService } from "../../../services/global.service";
import { HospitalService } from "../../../services/hospital.service";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from "@angular/forms";
import swal from "sweetalert2";
import { MustMatch } from "../../../_helpers/must-match.validator";
@Component({
  selector: "app-setting-hospital",
  templateUrl: "./setting-hospital.component.html",
  styleUrls: ["./setting-hospital.component.scss"]
})
export class SettingHospitalComponent implements OnInit {
  hospitals: any;
  hospital: any;
  hospitalForm: FormGroup;
  submitted = false;
  user: any;
  errors: any;
  validateIdCard: boolean = false;

  constructor(
    private Global: GlobalService,
    private hospitalService: HospitalService,
    private formBuilder: FormBuilder
  ) {
    var sessions = JSON.parse(sessionStorage.getItem("sessions"));
    this.user = sessions.user;
  }

  ngOnInit() {
    this.setForm();
    this.getHospitals();

    // if (this.user.role_id == 3) {
    //   this.getHospital(this.user.doctor.hospital_id);
    // }
  }

  getHospitals() {
    var filters = {};
    if (this.user.role_id == "3") {
      filters["id"] = this.user.doctor.hospital_id;
    }

    this.hospitalService.getHospitals(filters).subscribe(data => {
      this.hospitals = data;
    });
  }
  // getHospital(hospital_id) {
  //   this.hospitalService.showHospital(hospital_id).subscribe(data => {
  //     this.hospital = data;
  //     this.setForm();
  //     // this.showForm(null)
  //   });
  // }

  setForm() {
    this.hospitalForm = this.formBuilder.group(
      {
        name: ["", Validators.required],
        address: ["", Validators.required],
        idcard: ["", [Validators.required, Validators.maxLength(13)]],
        first_name: ["", Validators.required],
        last_name: ["", Validators.required],
        phone: ["", Validators.required],
        email: ["", [Validators.required, Validators.email]],
        password: ["", [Validators.required, Validators.minLength(4)]],
        password_confirmation: ["", Validators.required],
        id: ""
      },
      {
        validator: MustMatch("password", "password_confirmation")
      }
    );
  }

  showForm(hospital = null) {
    this.submitted = false;
    this.getHospitals();
    this.validateIdCard = true;
    if (hospital != null) {
      this.hospitalForm.patchValue(hospital);

      this.hospitalForm.get("password").clearValidators();
      this.hospitalForm.get("password_confirmation").clearValidators();
      this.hospitalForm.get("idcard").clearValidators();
      this.hospitalForm.get("first_name").clearValidators();
      this.hospitalForm.get("last_name").clearValidators();
      this.hospitalForm.get("phone").clearValidators();
      this.hospitalForm.get("email").clearValidators();

      this.hospitalForm.get("password").updateValueAndValidity();
      this.hospitalForm.get("password_confirmation").updateValueAndValidity();
      this.hospitalForm.get("idcard").updateValueAndValidity();
      this.hospitalForm.get("first_name").updateValueAndValidity();
      this.hospitalForm.get("last_name").updateValueAndValidity();
      this.hospitalForm.get("phone").updateValueAndValidity();
      this.hospitalForm.get("email").updateValueAndValidity();
    } else {
      this.setForm();
    }
  }

  get f() {
    return this.hospitalForm.controls;
  }

  onSubmit() {
    if (this.hospitalForm.controls.id.value == "") {
      var idcard = this.hospitalForm.controls.idcard.value;
      this.validateIdCard = this.Global.validateIdCard(idcard);
    }

    this.submitted = true;

    if (this.hospitalForm.invalid || !this.validateIdCard) {
      return;
    }

    swal({
      title: "ยืนยันการบันทึก",
      text: "คุณต้องการบันทึกข้อมูลใช่หรือไม่ ?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#36a3f7",
      cancelButtonColor: "#f4516c",
      confirmButtonText: "ใช่, บันทึกข้อมูล",
      cancelButtonText: "ยกเลิก"
    }).then(result => {
      if (result.value) {
        var obj = {
          hospital: {
            name: this.hospitalForm.controls.name.value,
            address: this.hospitalForm.controls.address.value
          }
        };

        var hospital_id = "";
        if (this.hospitalForm.controls.id.value != "") {
          hospital_id = this.hospitalForm.controls.id.value;
        } else {
          if (this.hospitalForm.controls.password.value != null) {
            obj.hospital[
              "password"
            ] = this.hospitalForm.controls.password.value.trim();
            obj.hospital[
              "password_confirmation"
            ] = this.hospitalForm.controls.password_confirmation.value.trim();
          }

          obj.hospital["idcard"] = this.hospitalForm.controls.idcard.value;
          obj.hospital[
            "first_name"
          ] = this.hospitalForm.controls.first_name.value;
          obj.hospital[
            "last_name"
          ] = this.hospitalForm.controls.last_name.value;
          obj.hospital["display_name"] =
            this.hospitalForm.controls.first_name.value +
            " " +
            this.hospitalForm.controls.last_name.value;
          obj.hospital["phone"] = this.hospitalForm.controls.phone.value;
          obj.hospital["email"] = this.hospitalForm.controls.email.value;
          obj.hospital["username"] = this.hospitalForm.controls.email.value;
        }

        this.hospitalService.submitHospital(obj, hospital_id).subscribe(
          res => {
            swal({
              type: "success",
              title: "บันทึกข้อมูลเรียบร้อยแล้ว",
              showConfirmButton: false,
              timer: 1500
            });
            this.getHospitals();
            this.setForm();
            this.submitted = false;
            this.Global.loadScript("assets/script/modal.js");
          },
          error => {
            this.errors = error.error;
            swal({
              type: "warning",
              title: "การบันทึกข้อมูลผิดพลาด",
              showConfirmButton: true
            });
          }
        );
      }
    });
  }

  delete(hospital_id) {
    swal({
      title: "ยืนยันการลบข้อมูล",
      text: "คุณต้องการลบข้อมูลใช่หรือไม่ ?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#36a3f7",
      cancelButtonColor: "#f4516c",
      confirmButtonText: "ใช่, ลบข้อมูล",
      cancelButtonText: "ยกเลิก"
    }).then(result => {
      if (result.value) {
        this.hospitalService.deleteHospital(hospital_id).subscribe(data => {
          swal({
            type: "success",
            title: "ลบข้อมูลเรียบร้อยแล้ว",
            showConfirmButton: false,
            timer: 1500
          });
          this.getHospitals();
        });
      }
    });
  }
}
