import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { HeaderNavComponent } from './header-nav/header-nav.component';
import { FooterComponent } from './footer/footer.component';
import { ScrollTopComponent } from './scroll-top/scroll-top.component';
import { HeaderBottomComponent } from './header-bottom/header-bottom.component';

@NgModule({
  declarations: [
    HeaderNavComponent,
    FooterComponent,
    ScrollTopComponent,
    HeaderBottomComponent,
  ],
  exports: [
    HeaderNavComponent,
    FooterComponent,
    ScrollTopComponent,
    HeaderBottomComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
  ]
})
export class LayoutsModule { }
