import { Component, OnInit } from '@angular/core';
import { GlobalService } from "../../services/global.service";
import { PatientService } from "../../services/patient.service";
@Component({
  selector: 'app-header-bottom',
  templateUrl: './header-bottom.component.html',
  styleUrls: ['./header-bottom.component.scss']
})
export class HeaderBottomComponent implements OnInit {
  patient: any = {};
  patient_id: any = sessionStorage.getItem('patient_id')

  constructor(
    private Global: GlobalService,
    private PatientService: PatientService
  ) { }

  ngOnInit() {
    this.getPatient()
  }

  getPatient() {
    this.PatientService.showPatient(this.patient_id).subscribe(data => {
      this.patient = data
    })
  }

}
