import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { service_url } from "./service-url";

@Injectable({
  providedIn: 'root'
})
export class CarerService {
  api_url: any
  constructor(private http: HttpClient) {
    this.api_url = service_url.api_url
  }

  getCarers(filters = {}) {
    let params = new HttpParams();
    Object.keys(filters).forEach(function (key) {
      params = params.append(key, filters[key]);
    });
    return this.http.get(this.api_url + '/carers', { params: params })
  }

  showCarer(carer_id) {
    return this.http.get(this.api_url + '/carers/' + carer_id)
  }

  submitCarer(params, id = '') {

    if (id != '') {
      return this.http.put(this.api_url + '/carers/' + id, params)
    }
    else {
      return this.http.post(this.api_url + '/carers', params)
    }
  }

  deleteCarer(carer_id) {
    return this.http.delete(this.api_url + '/carers/' + carer_id)
  }
}
