import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ComponentsComponent } from "./components.component";
import { ScheduleComponent } from "./schedule/schedule.component";

import { PatientListComponent } from "./patients/patient-list/patient-list.component";
import { PatientComponent } from "./patients/patient/patient.component";
import { PatientDashboardComponent } from "./patients/patient-dashboard/patient-dashboard.component";
import { PatientTestComponent } from './patients/patient-test/patient-test.component';
import { PatientTest1DetailComponent } from './patients/patient-test1-detail/patient-test1-detail.component';
import { PatientTest2DetailComponent } from './patients/patient-test2-detail/patient-test2-detail.component';
import { PatientScheduleComponent } from './patients/patient-schedule/patient-schedule.component';
import { PatientMedicineComponent } from './patients/patient-medicine/patient-medicine.component';
import { PatientPostsComponent } from './patients/patient-posts/patient-posts.component';
import { NewsComponent } from './news/news.component';
import { ApproveUserComponent } from './approve-user/approve-user.component';
import { SettingHospitalComponent } from './settings/setting-hospital/setting-hospital.component';
import { SettingDoctorComponent } from './settings/setting-doctor/setting-doctor.component';
import { SettingCarerComponent } from './settings/setting-carer/setting-carer.component';
import { SettingMedicineComponent } from './settings/setting-medicine/setting-medicine.component';

const routes: Routes = [
  {
    path: '',
    component: ComponentsComponent,
    children: [
      {
        path: 'patient',
        component: PatientListComponent,
      },
      {
        path: 'patient',
        component: PatientComponent,
        children: [
          {
            path: 'dashboard',
            component: PatientDashboardComponent
          },
          {
            path: 'test',
            component: PatientTestComponent
          },
          {
            path: 'test1-detail',
            component: PatientTest1DetailComponent
          },
          {
            path: 'test2-detail',
            component: PatientTest2DetailComponent
          },
          {
            path: 'schedule',
            component: PatientScheduleComponent
          },
          {
            path: 'medicine',
            component: PatientMedicineComponent
          },
          {
            path: 'posts',
            component: PatientPostsComponent
          },
        ]
      },
      {
        path: 'schedule',
        component: ScheduleComponent
      },
      {
        path: 'news',
        component: NewsComponent
      },
      {
        path: 'approve-user',
        component: ApproveUserComponent
      },
      {
        path: 'setting-hospital',
        component: SettingHospitalComponent
      },
      {
        path: 'setting-doctor',
        component: SettingDoctorComponent
      },
      {
        path: 'setting-carer',
        component: SettingCarerComponent
      },
      {
        path: 'setting-medicine',
        component: SettingMedicineComponent
      },

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ComponentsRoutingModule { }
