import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { service_url } from "./service-url";

@Injectable({
  providedIn: 'root'
})
export class PatientPostService {
  api_url: any
  options = {}

  constructor(private http: HttpClient) {
    this.api_url = service_url.api_url

  }

  getPosts(id) {
    return this.http.get(this.api_url + "/posts?patient_id=" + id)
  }

  showPost(post_id) {
    return this.http.get(this.api_url + '/posts/' + post_id)
  }

  submitPost(params, post_id = '') {

    if (post_id != '') {
      return this.http.put(this.api_url + '/posts/' + post_id, params)
    }
    else {
      return this.http.post(this.api_url + '/posts', params)
    }
  }

  deletePost(post_id) {
    return this.http.delete(this.api_url + '/posts/' + post_id)
  }
}
