import { Component, OnInit, ViewChild, SimpleChanges } from "@angular/core";
import { GlobalService } from "../../../../services/global.service";
import { PatientService } from "../../../../services/patient.service";
import { service_url } from "../../../../services/service-url";
import { PatientTestsService } from "../../../../services/patient-tests.service";
import { Router } from "@angular/router";
import { MatTableDataSource, MatSort, MatPaginator } from "@angular/material";
import { BaseChartDirective } from "ng2-charts";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
@Component({
  selector: "app-section-test2",
  templateUrl: "./section-test2.component.html",
  styleUrls: ["./section-test2.component.scss"],
})
export class SectionTest2Component implements OnInit {
  _onLangChange: any;
  currentLang: string;

  title_test2: string;
  title_test2_compare: string;
  month: boolean = true;
  year: boolean = false;
  wearable: any;
  wearable_pie: any;
  month_cur: any = new Date().getMonth();
  title_month: any;
  patient: any = {};
  api_url = service_url.api_url;
  patient_id: any = sessionStorage.getItem("patient_id");
  radarData: any = [];
  radarDataCompare: any = [];
  months: any = this.global.month_thai_arr;
  month_list: any;
  radarChartOptions = {
    legend: {
      position: "bottom",
      labels: {
        padding: 40,
        fontColor: "#575962",
        fontFamily: "Kanit",
        fontSize: 14,
        fontStyle: "500",
      },
    },
    scale: {
      pointLabels: {
        fontColor: "#575962",
        fontFamily: "Kanit",
        fontSize: 14,
      },
      ticks: {
        fontColor: "#575962",
        fontFamily: "Kanit",
        fontSize: 14,
        fontStyle: "500",
        beginAtZero: true,
        // steps: 1,
        // stepValue: 1,
        stepSize: 1,
        max: 5,
      },
    },
  };

  public radarChartColors: Array<any> = [
    {
      // second color
      backgroundColor: "rgba(	236, 0, 50,0.5)",
      borderColor: "rgba(	236, 0, 50,0.5)",
      pointBackgroundColor: "rgba(	236, 0, 50,0.5)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(	236, 0, 50,0.5)",
    },
    {
      // first color
      backgroundColor: "rgba(21,133,209,0.5)",
      borderColor: "rgba(21,133,209,0.5)",
      pointBackgroundColor: "rgba(21,133,209,0.5)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(21,133,209,0.5)",
    },
  ];

  radarLabels = [
    "Delusion",
    "Hallucination",
    "Affect regulation",
    "Anxious and distress",
    "Apathy",
    "Disinhibition",
    "Stereotypy",
    "Eating problem",
    "Sleep problem",
  ];

  patient_test_2: MatTableDataSource<any>;
  test2DisplayedColumns: string[] = [
    "created_date",
    "total_score_frequency",
    "total_score_effect",
    "display_name",
  ];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(BaseChartDirective) public _chart2: BaseChartDirective;
  @ViewChild("baseChartRadar") chart_radar: BaseChartDirective;
  @ViewChild("baseChartRadarCompare") chart_radar_compare: BaseChartDirective;
  @ViewChild("baseChart") chart: BaseChartDirective;
  mode: any = "latest";
  compare: boolean = false;
  test_compare: any = [];
  constructor(
    private global: GlobalService,
    private PatientService: PatientService,
    private PatientTestsService: PatientTestsService,
    private router: Router,
    private translate: TranslateService
  ) {
    this._onLangChange = this.translate.onLangChange.subscribe(
      (event: LangChangeEvent) => {
        this.currentLang = this.translate.currentLang;
        this.getPatientTests2(this.mode);
      }
    );
  }

  ngOnInit() {
    this.getPatientTests2("latest");
    this.getPatientTest2List();
    this.title_month = this.global.setMonthThaiShort();
    this.month_list = this.title_month;
  }

  ngOnDestroy() {
    this._onLangChange.unsubscribe();
  }

  getMonth(month) {
    // current date 2018-02-26
    var mm = month;

    var m;
    if (mm < 10) {
      m = "0" + mm;
    } else {
      m = mm;
    }

    return m;
  }

  filter(mode) {
    const filters = {
      test_id: 2,
      order: "id desc",
      patient_id: this.patient_id,
    };

    if (mode == "latest") {
      filters.order = "id desc";
      filters["limit"] = 1;
    } else if (mode == "average") {
      filters.order = "updated_at asc";
    }

    return filters;
  }

  groupBtn() {
    this.compare = false;
  }

  getPatientTest2List() {
    const filters = {
      test_id: 2,
      order: "id asc",
      patient_id: this.patient_id,
    };
    this.PatientTestsService.getPatientTests(filters).subscribe((res) => {
      let data;
      data = res;
      data.forEach((element, index) => {
        let created_date = this.global.setDateFormatShort(
          element.created_date,
          this.currentLang
        );
        let item = "ครั้งที่ " + (index + 1) + " วันที่ " + created_date;
        if (index < data.length - 1) {
          this.test_compare.push({ name: item, id: element.id });
        }
      });
    });
  }

  getPatientTests2(mode = "latest") {
    this.mode = mode;
    var filters = this.filter(mode);

    this.radarData = [
      { data: [], label: "ความถี่" },
      { data: [], label: "ผลกระทบต่อจิตใจ" },
    ];

    this.PatientTestsService.getPatientTests(filters).subscribe((data) => {
      console.log(data);

      let patient_test_2;
      patient_test_2 = data;

      let array = patient_test_2.map((element) => {
        const score_frequency = [];
        const score_effect = [];

        element.patient_test_values.forEach((patient_test_value) => {
          if (patient_test_value.has_sub_value != false) {
            if (patient_test_value.patient_test_sub_values[0]) {
              score_frequency.push(
                patient_test_value.patient_test_sub_values[0].value
              );
            }
            if (patient_test_value.patient_test_sub_values[1]) {
              score_effect.push(
                patient_test_value.patient_test_sub_values[1].value
              );
            }
          } else {
            score_frequency.push(0);
            score_effect.push(0);
          }
        });
        const total_score_effect = score_effect.reduce(
          (total_score_effect, current) => total_score_effect + current,
          0
        );
        const total_score_frequency = score_frequency.reduce(
          (total_score_frequency, current) => total_score_frequency + current,
          0
        );
        element.total_score_effect = total_score_effect;
        element.total_score_frequency = total_score_frequency;

        element.created_date = this.global.setDateFormatShort(
          element.created_date,
          this.currentLang
        );
        element.display_name = element.user.display_name;

        return element;
      });

      const dataFrequently = [];
      const dataEffect = [];

      for (let i = 0; i < 9; i++) {
        dataFrequently.push({ data: [] });
        dataEffect.push({ data: [] });
      }

      for (let i = 0; i < patient_test_2.length; i++) {
        for (let index = 0; index < 9; index++) {
          console.log(index);
          const item = patient_test_2[i].patient_test_values[index];

          dataFrequently[index].data.push(
            item.has_sub_value && item.patient_test_sub_values.length > 0
              ? item.patient_test_sub_values[0].value
              : 0
          );
          dataEffect[index].data.push(
            item.has_sub_value && item.patient_test_sub_values.length > 0
              ? item.patient_test_sub_values[1].value
              : 0
          );
          if (this.mode == "latest") {
            this.title_test2 = "วันที่ " + patient_test_2[0].created_date;
            this.radarData[0].data.push(
              patient_test_2[0].patient_test_values[index].has_sub_value &&
                patient_test_2[0].patient_test_values[index]
                  .patient_test_sub_values.length > 0
                ? patient_test_2[0].patient_test_values[index]
                    .patient_test_sub_values[0].value
                : 0
            );
            this.radarData[1].data.push(
              patient_test_2[0].patient_test_values[index].has_sub_value &&
                patient_test_2[0].patient_test_values[index]
                  .patient_test_sub_values.length > 0
                ? patient_test_2[0].patient_test_values[index]
                    .patient_test_sub_values[1].value
                : 0
            );
          }
        }
      }

      if (this.mode == "average") {
        dataFrequently.forEach((element) => {
          var avg = 0;
          element.data.forEach((data) => {
            avg += data;
          });
          avg = avg / patient_test_2.length;
          this.radarData[0].data.push(this.round(avg, 2));
        });

        dataEffect.forEach((element) => {
          var avg = 0;
          element.data.forEach((data) => {
            avg += data;
          });
          avg = avg / patient_test_2.length;
          this.radarData[1].data.push(this.round(avg, 2));
        });
      }

      this.refreshChart();

      this.patient_test_2 = new MatTableDataSource(array);
      this.patient_test_2.sort = this.sort;
      this.patient_test_2.paginator = this.paginator;
    });
  }

  compareTest(test_compare = null) {
    this.compare = true;
    const filters = {
      test_id: 2,
      order: "id desc",
      patient_id: this.patient_id,
      limit: 2,
    };
    if (test_compare != null) {
      filters["id"] = test_compare.id;
    }

    this.radarDataCompare = [
      { data: [], label: "ความถี่" },
      { data: [], label: "ผลกระทบต่อจิตใจ" },
    ];

    this.PatientTestsService.getPatientTests(filters).subscribe((data) => {
      let patient_test_2;
      patient_test_2 = data;

      let title_test2_compare;
      for (let index = 0; index < 9; index++) {
        if (test_compare == null) {
          if (patient_test_2[1]) {
            title_test2_compare = patient_test_2[1]
              ? patient_test_2[1].created_date
              : "";
            this.radarDataCompare[0].data.push(
              patient_test_2[1].patient_test_values[index].has_sub_value &&
                patient_test_2[1].patient_test_values[index]
                  .patient_test_sub_values.length > 0
                ? patient_test_2[1].patient_test_values[index]
                    .patient_test_sub_values[0].value
                : 0
            );
            this.radarDataCompare[1].data.push(
              patient_test_2[1].patient_test_values[index].has_sub_value &&
                patient_test_2[1].patient_test_values[index]
                  .patient_test_sub_values.length > 0
                ? patient_test_2[1].patient_test_values[index]
                    .patient_test_sub_values[1].value
                : 0
            );
          }
        } else if (test_compare != null) {
          title_test2_compare = patient_test_2
            ? patient_test_2.created_date
            : "";
          this.radarDataCompare[0].data.push(
            patient_test_2.patient_test_values[index].has_sub_value &&
              patient_test_2.patient_test_values[index].patient_test_sub_values
                .length > 0
              ? patient_test_2.patient_test_values[index]
                  .patient_test_sub_values[0].value
              : 0
          );
          this.radarDataCompare[1].data.push(
            patient_test_2.patient_test_values[index].has_sub_value &&
              patient_test_2.patient_test_values[index].patient_test_sub_values
                .length > 0
              ? patient_test_2.patient_test_values[index]
                  .patient_test_sub_values[1].value
              : 0
          );
        }

        title_test2_compare =
          "ครั้งที่ " +
          this.test_compare.length +
          " วันที่ " +
          this.global.setDateFormatShort(title_test2_compare, this.currentLang);
        this.title_test2_compare =
          test_compare != null ? test_compare.name : title_test2_compare;
      }

      this.refreshChart();
    });
  }

  detailPage(test) {
    if (test.test_id == 1) {
      this.router.navigate(["/patient/test1-detail"], {
        queryParams: { test: test.id },
      });
    } else if (test.test_id == 2) {
      this.router.navigate(["/patient/test2-detail"], {
        queryParams: { test: test.id },
      });
    }
  }

  refreshChart() {
    if (this.chart_radar_compare !== undefined) {
      this.chart_radar_compare.ngOnDestroy();
      this.chart_radar_compare.chart = this.chart_radar_compare.getChartBuilder(
        this.chart_radar_compare.ctx
      );
    }

    if (this.chart_radar !== undefined) {
      this.chart_radar.ngOnDestroy();
      this.chart_radar.chart = this.chart_radar.getChartBuilder(
        this.chart_radar.ctx
      );
    }
  }

  round(number, precision) {
    var factor = Math.pow(10, precision);
    var tempNumber = number * factor;
    var roundedTempNumber = Math.round(tempNumber);
    return roundedTempNumber / factor;
  }
}
