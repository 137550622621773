import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { service_url } from "./service-url";

@Injectable({
  providedIn: 'root'
})
export class PatientService {
  api_url: any
  constructor(private http: HttpClient) {
    this.api_url = service_url.api_url
  }

  getPatients(filters) {
    let params = new HttpParams();
    Object.keys(filters).forEach(function (key) {
      params = params.append(key, filters[key]);
    });
    return this.http.get(this.api_url + '/patients', { params: params })
  }

  showPatient(patient_id) {
    return this.http.get(this.api_url + '/patients/' + patient_id)
  }

  getCarerRelationships() {
    return this.http.get(this.api_url + '/carer_relationships')
  }

  submitPatient(params, id = '') {
    if (id != '') {
      return this.http.put(this.api_url + '/patients/' + id, { patient: params })
    }
    else {
      return this.http.post(this.api_url + '/patients', { patient: params })
    }
  }

  deletePatient(patient_id) {
    return this.http.delete(this.api_url + '/patients/' + patient_id)
  }
  deleteCarerPatients(carer_patien_id) {
    return this.http.delete(this.api_url + '/carer_patients/' + carer_patien_id)
  }

  getCarerPatients(filters) {
    let params = new HttpParams();
    Object.keys(filters).forEach(function (key) {
      params = params.append(key, filters[key]);
    });
    return this.http.get(this.api_url + '/carer_patients', { params: params })
  }

  getDoctorPatients(filters) {
    let params = new HttpParams();
    Object.keys(filters).forEach(function (key) {
      params = params.append(key, filters[key]);
    });
    return this.http.get(this.api_url + '/doctor_patients', { params: params })
  }
}
