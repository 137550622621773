import { Component, OnInit, ViewChild } from '@angular/core';
@Component({
  selector: 'app-setting-medicine',
  templateUrl: './setting-medicine.component.html',
  styleUrls: ['./setting-medicine.component.scss']
})
export class SettingMedicineComponent implements OnInit {


  constructor(

  ) { }

  ngOnInit() {

  }



}
