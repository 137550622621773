import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { service_url } from "./service-url";

@Injectable({
  providedIn: 'root'
})
export class MedicineTakingService {
  api_url: any
  constructor(private http: HttpClient) {
    this.api_url = service_url.api_url
  }

  getMedicineTakeTimes(filters) {
    let params = new HttpParams();
    Object.keys(filters).forEach(function (key) {
      params = params.append(key, filters[key]);
    });
    return this.http.get(this.api_url + '/medicine_take_times', { params: params })
  }
  getMedicineTakeTimeGroups() {
    return this.http.get(this.api_url + '/medicine_take_time_groups')
  }
  getMealTimes() {
    return this.http.get(this.api_url + '/meal_times')
  }
  getMedicineTakings(filters) {
    let params = new HttpParams();
    Object.keys(filters).forEach(function (key) {
      params = params.append(key, filters[key]);
    });
    return this.http.get(this.api_url + '/medicine_takings', { params: params })
  }
  showMedicineTaking(medicine_taking_id) {
    return this.http.get(this.api_url + '/medicine_takings/' + medicine_taking_id)
  }
  submitMedicineTaking(params) {
    return this.http.post(this.api_url + '/medicine_takings', params)
  }
}
