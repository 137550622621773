import { NgModule, Injectable, Inject } from '@angular/core';
import { HttpClientModule, HttpResponse, HttpErrorResponse, HttpRequest, HTTP_INTERCEPTORS, HttpInterceptor, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment as ENV } from '../../environments/environment';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class AuthService implements HttpInterceptor {

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var token = JSON.parse(sessionStorage.getItem('sessions'));

    if (token != null) {
      token = token['access_token']
      req = req.clone({
        setHeaders: {
          Authorization: token
        }
      });

    }
    return next.handle(req);

  }
}
