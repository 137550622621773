import { Component, OnInit, ViewChild, Pipe } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
} from "@angular/forms";
import swal from "sweetalert2";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatTableDataSource,
  MatSort,
  MatPaginator,
  PageEvent,
} from "@angular/material";
import { debounceTime, debounce, distinctUntilChanged } from "rxjs/operators";
import { Subject } from "rxjs";

import { PatientService } from "../../../services/patient.service";
import { GlobalService } from "../../../services/global.service";
import { ScriptLoaderService } from "src/app/_services/script-loader.service";
import { CarerService } from "../../../services/carer.service";
import { DoctorService } from "../../../services/doctor.service";
import { MedicineTakingService } from "../../../services/medicine-taking.service";
import { DoctorPatientService } from "../../../services/doctor-patient.service";
import { CarerPatientService } from "../../../services/carer-patient.service";
import { WizardComponent } from "angular-archwizard";
import { GenderService } from "src/app/services/gender.service";
import { ExcelService } from "src/app/_services/excel.service";
import * as moment from "moment";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";

@Component({
  selector: "app-patient-list",
  templateUrl: "./patient-list.component.html",
  styleUrls: ["./patient-list.component.scss"],
})
export class PatientListComponent implements OnInit {
  _onLangChange: any;
  currentLang: string;

  patientForm: FormGroup;
  doctorPatientForm: FormGroup;
  carerPatientForm: FormGroup;
  patientMealTimeForm: FormGroup;
  filtersForm: FormGroup;
  submitted = false;
  carers: any;
  doctors: any;
  carer_relationships: any;
  meal_times: any;
  carer_patient_statuses: any;
  new_carer_patients: any = [];
  carer_patients: any = [];
  birth_date: any = new Date();
  range = { start: null, end: null };
  patients: MatTableDataSource<any>;
  displayedColumns: string[] = [
    "index",
    "last_patient_test",
    "first_name",
    "last_name",
    "age",
    "carers",
    "doctors",
    "action",
  ];
  @ViewChild(MatSort) sort: MatSort;
  // @ViewChild(MatPaginator) paginator: MatPaginator
  index_carer_patient: any;
  debounceTime = new Subject();
  addCarer: boolean = false;
  // invalid = { patient: false, doctorPatien: false, carerPatient: false, patientMeal: false };
  patient_id: any;
  navigationMode: string = "free";
  defaultStepIndex: number = 0;
  canExit: boolean;
  editForm: boolean = false;
  validateIdCard: boolean = false;
  user: any;

  genderOptions: Array<any> = [];
  exportloading: boolean = false;
  isOpen: boolean = false;

  limit: number = 25;
  totalLength: number = 0;
  pageIndex: number = 0;
  pageLimit: number[] = [25, 50, 100];

  @ViewChild(WizardComponent)
  public wizard: WizardComponent;
  forms_data: any = { patient: {}, doctor_patient: [], carer_patient: [] };
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    public datepipe: DatePipe,
    private global: GlobalService,
    private patientService: PatientService,
    private CarerService: CarerService,
    private DoctorService: DoctorService,
    private MedicineTakingService: MedicineTakingService,
    private DoctorPatientService: DoctorPatientService,
    private CarerPatientService: CarerPatientService,
    private GenderService: GenderService,
    private _excelService: ExcelService,
    private translate: TranslateService
  ) {
    var sessions = JSON.parse(sessionStorage.getItem("sessions"));
    this.user = sessions.user;
    this.global.sessions = sessions;
    this.global.setUser();
    this.translate.use("th");
    this._onLangChange = this.translate.onLangChange.subscribe(
      (event: LangChangeEvent) => {
        this.currentLang = this.translate.currentLang;
        this.getPatients();
      }
    );
  }

  ngOnInit() {
    this.setFiltersForm();
    this.setForm();
    this.getPatients();
    this.getGenders();

    this.debounceTime
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((data) => {
        this.getPatients();
      });
  }

  ngOnDestroy() {
    this._onLangChange.unsubscribe();
  }

  setFiltersForm() {
    this.filtersForm = this.formBuilder.group({
      keywords: "",
      idcard: "",
    });
  }

  onChange(value) {
    this.birth_date = value;
  }

  rangeChange(range) {
    this.range.end = range.end;
    this.range.start = range.start;
    this.getPatients();
  }

  setForm() {
    this.birth_date = new Date();
    this.carer_patients = [];
    this.patientForm = this.formBuilder.group({
      idcard: [null, Validators.required],
      first_name: ["", Validators.required],
      last_name: ["", Validators.required],
      gender_id: ["", Validators.required],
      congenital_disease: "",
      id: "",
    });

    this.doctorPatientForm = this.formBuilder.group({
      doctor_patients: [null, Validators.required],
      doctor_patients_secondary: null,
    });

    this.carerPatientForm = this.formBuilder.group({
      carer_patients: [null],
      carer_relationship_id: [null],
      carer_patient_status_id: [null],
    });

    this.patientMealTimeForm = this.formBuilder.group({
      meal_times: this.formBuilder.array([], Validators.required),
    });

    this.addMealTime();
  }

  get formArray() {
    return this.patientMealTimeForm.get("meal_times") as FormArray;
  }

  private addMealTime() {
    this.MedicineTakingService.getMealTimes().subscribe((data) => {
      let arr: any = data;
      this.meal_times = arr.filter((item) => item.id != 5);
      this.meal_times.forEach((element) => {
        this.formArray.push(
          this.formBuilder.group({
            time: element.time,
          })
        );
      });
    });
  }

  showForm(patient = null) {
    this.defaultStepIndex = 1;
    this.index_carer_patient = undefined;
    this.submitted = false;
    this.addCarer = false;
    this.validateIdCard = true;
    this.getPatients();
    this.getCarers();
    this.getDoctors();
    this.getCarerRelationships();
    this.getMealtimes();
    this.carer_patient_statuses = [
      {
        id: 1,
        name: "ผู้ดูแลหลัก",
      },
      {
        id: 2,
        name: "ผู้ดูแลรอง",
      },
    ];
    if (patient != null) {
      this.editForm = true;
      this.navigationMode = "free";
      if (patient["birth_date"] != null) {
        this.birth_date = new Date(patient["birth_date"]);
      }

      var doctor_patients_main = [];
      var doctor_patients_secondary = [];
      patient["doctor_patients"].forEach((element) => {
        if (element.doctor_patient_status_id == 1) {
          doctor_patients_main.push({
            id: element.doctor_id,
            display_name: element.doctor.display_name,
          });
        } else if (element.doctor_patient_status_id == 2) {
          doctor_patients_secondary.push({
            id: element.doctor_id,
            display_name: element.doctor.display_name,
          });
        }
      });

      var patient_meal_times = [];
      patient["patient_meal_times"].forEach((element) => {
        var obj = {
          time: element.time,
        };
        patient_meal_times.push(obj);
      });

      this.patientForm.patchValue(patient);

      this.doctorPatientForm = this.formBuilder.group({
        doctor_patients: [doctor_patients_main, Validators.required],
        doctor_patients_secondary: [doctor_patients_secondary],
      });

      this.carerPatientForm = this.formBuilder.group({
        carer_relationship_id: null,
        carer_patients: null,
        carer_patient_status_id: null,
      });

      this.formArray.patchValue(patient_meal_times);

      this.carer_patients = patient["carer_patients"];
      for (let i = 0; i < this.carer_patients.length; i++) {
        const element = this.carer_patients[i];
        element.carer_patients =
          element.carer.idcard + " ( " + element.carer.display_name + " )";
        var new_carer_patient = {
          carer_id: element.carer_id,
          carer_patient_status_id: element.carer_patient_status_id,
          carer_relationship_id: element.carer_relationship_id,
        };
        this.new_carer_patients.push(new_carer_patient);
      }
    } else {
      this.editForm = false;
      this.navigationMode = "strict";
      this.setForm();
    }
  }

  idCardFilter(idcard) {
    if (idcard != "") {
      var idcardLength = new String(idcard).length;
      if (idcardLength != 13) {
        var array = [];
        this.patients = new MatTableDataSource(array);
      } else {
        this.debounceTime.next(idcard);
      }
    } else {
      this.getPatients();
    }
  }

  keywordFilter(keywords) {
    this.debounceTime.next(keywords);
  }

  searchPatientSelect() {
    this.isOpen = true;
  }

  changePage(event) {
    this.pageIndex = event.pageIndex;
    this.getPatients();
  }

  getPatients() {
    var filters = {
      order: "patient_tests.created_at desc",
      datatable: true,
      limit: this.limit,
      offset: this.limit * this.pageIndex,
    };

    if (this.user.role_id == 3) {
      filters["hospital_id"] = this.user.doctor.hospital_id;
    }

    var filtersForm = this.filtersForm.value;

    if (filtersForm.keywords != "") {
      filters["keywords"] = filtersForm.keywords;
      filters["offset"] = 0;
    }

    if (filtersForm.idcard == "") {
      if (this.global.user.role_id == 2) {
        filters["doctor_id"] = this.global.doctor_id;
      }
    } else {
      var idcardLength = new String(filtersForm.idcard).length;
      if (idcardLength == 13) {
        filters["keywords"] = filtersForm.idcard;
      } else {
        if (this.global.user.role_id == 2) {
          filters["doctor_id"] = this.global.doctor_id;
        }
      }
    }

    var range_start = this.datepipe.transform(this.range.start, "yyyy-MM-dd");
    var range_end = this.datepipe.transform(this.range.end, "yyyy-MM-dd");

    if (this.range.end != null && this.range.start != null) {
      filters["patient_tests_created_date_start"] = range_start;
      filters["patient_tests_created_date_end"] = range_end;
    } else if (this.range.end == null && this.range.start != null) {
      filters["patient_tests_created_date"] = range_start;
    }

    this.patientService.getPatients(filters).subscribe((data) => {
      let patients;
      patients = data["data"];
      this.totalLength = data["total"];
      let array = patients.map((element, index) => {
        if (element.birth_date != null) {
          element.age = this.ageFormDateOfBirthday(element.birth_date);
        }

        element.carer_patients.forEach((carer_patient) => {
          carer_patient.carer_patients = carer_patient.carer.display_name;
          if (carer_patient.carer_patient_status_id == 1) {
            element.carers = carer_patient.carer_patients;
          }
        });
        if (element.doctor_patients.length != 0) {
          element.doctors = element.doctor_patients[0].doctor.display_name;
        }
        element.index = index + 1;
        if (element.last_patient_test != null) {
          var date = this.global.setDateThaiShotDataFormServer(
            element.last_patient_test.created_at,
            this.currentLang
          );
          var time = this.global.settimeDataFormServer(
            element.last_patient_test.created_at
          );
          element.last_patient_test = date + " " + time;
        }

        return element;
      });

      this.patients = new MatTableDataSource(array);
      this.patients.sort = this.sort;
      // this.patients.paginator = this.paginator
    });
  }

  public ageFormDateOfBirthday(dateOfBirth: any) {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);

    var a = moment(today);
    var b = moment(birthDate);

    var years = a.diff(b, "year");
    b.add(years, "years");

    var months = a.diff(b, "months");
    b.add(months, "months");

    // var days = a.diff(b, 'days');

    return (
      years +
      " " +
      this.translate.instant("YEAR") +
      " " +
      months +
      " " +
      this.translate.instant("MONTH")
    );
  }

  getCarers() {
    this.CarerService.getCarers().subscribe((data) => {
      this.carers = data;
      this.carers.forEach((element) => {
        element.display_name =
          element.idcard + " ( " + element.display_name + " )";
      });
    });
  }

  getDoctors() {
    let params = {};
    if (this.user.role_id == 2 || this.user.role_id == 3) {
      params["hospital_id"] = this.user.doctor.hospital_id;
    }
    this.DoctorService.getDoctors(params).subscribe((data) => {
      this.doctors = data;
    });
  }

  getCarerRelationships() {
    this.patientService.getCarerRelationships().subscribe((data) => {
      this.carer_relationships = data;
    });
  }

  getMealtimes() {
    this.MedicineTakingService.getMealTimes().subscribe((data) => {
      this.meal_times = data;
    });
  }

  getGenders() {
    this.GenderService.getList().subscribe((results: Array<any>) => {
      this.genderOptions = results;
    });
  }

  showPatientDashboard(patient_id) {
    sessionStorage.setItem("patient_id", patient_id);
  }

  addCarerPatient() {
    this.addCarer = true;
    if (this.carerPatientForm.value.carer_patients == null) {
      return;
    }
    if (this.carerPatientForm.value.carer_relationship_id == null) {
      return;
    }
    if (this.carerPatientForm.value.carer_patient_status_id == null) {
      return;
    }

    var new_carer_patient = {
      carer_id: this.carerPatientForm.controls.carer_patients.value.id,
      carer_patient_status_id: this.carerPatientForm.controls
        .carer_patient_status_id.value.id,
      carer_relationship_id: this.carerPatientForm.controls
        .carer_relationship_id.value.id,
    };
    this.new_carer_patients.push(new_carer_patient);

    var carer_patients = {
      carer_patients: this.carerPatientForm.controls.carer_patients.value
        .display_name,
      carer_patient_status_name: this.carerPatientForm.controls
        .carer_patient_status_id.value.name,
      carer_relationship_name: this.carerPatientForm.controls
        .carer_relationship_id.value.name,
    };
    this.carer_patients.push(carer_patients);

    this.carerPatientForm.patchValue({
      carer_patients: null,
      carer_patient_status_id: null,
      carer_relationship_id: null,
    });

    this.addCarer = false;
    this.isOpen = false;
  }

  get patientFormControl() {
    return this.patientForm.controls;
  }
  get doctorPatientFormControl() {
    return this.doctorPatientForm.controls;
  }
  get carerPatientFormControl() {
    return this.carerPatientForm.controls;
  }
  get patientMealTimeFormControl() {
    return this.patientMealTimeForm.controls;
  }

  submitPatientForm() {
    var idcard = this.patientForm.controls.idcard.value;
    // this.validateIdCard = this.global.validateIdCard(idcard)
    this.validateIdCard = true;

    this.submitted = true;
    if (this.patientForm.invalid || !this.validateIdCard) {
      return;
    } else {
      this.wizard.navigation.goToNextStep();
    }

    var patient = {
      idcard: idcard,
      first_name: this.patientForm.controls.first_name.value,
      last_name: this.patientForm.controls.last_name.value,
      display_name:
        this.patientForm.controls.first_name.value +
        " " +
        this.patientForm.controls.last_name.value,
      birth_date: this.datepipe.transform(this.birth_date, "yyyy-MM-dd"),
      gender_id: this.patientForm.controls.gender_id.value,
      congenital_disease: this.patientForm.controls.congenital_disease.value,
    };

    if (this.patientForm.value.id != "") {
      var id = this.patientForm.value.id;
      this.patientService.submitPatient(patient, id).subscribe((res) => {
        this.patient_id = res["id"];
        this.submitted = false;
        if (this.patientForm.controls.id) {
          swal({
            type: "success",
            title: "แก้ไขข้อมูลเรียบร้อยแล้ว",
            showConfirmButton: false,
            timer: 1500,
          });
          this.getPatients();
        }
      });
    } else {
      this.forms_data.patient = patient;
    }
  }

  submitDoctorPatientForm() {
    this.submitted = true;
    if (this.doctorPatientForm.invalid) {
      return;
    } else {
      this.wizard.navigation.goToNextStep();
    }

    var new_doctor_patients = [];
    var doctor_main = this.doctorPatientForm.controls.doctor_patients.value;
    if (doctor_main != null) {
      doctor_main.forEach((element) => {
        new_doctor_patients.push({
          doctor_id: element.id,
          doctor_patient_status_id: 1,
        });
      });
    }
    var doctor_secondary = this.doctorPatientForm.controls
      .doctor_patients_secondary.value;
    if (doctor_secondary != null) {
      doctor_secondary.forEach((element) => {
        new_doctor_patients.push({
          doctor_id: element.id,
          doctor_patient_status_id: 2,
        });
      });
    }

    for (let i = 0; i < new_doctor_patients.length; i++) {
      const element = new_doctor_patients[i];
      var doctor_patient = {
        doctor_id: element.doctor_id,
        doctor_patient_status_id: element.doctor_patient_status_id,
      };

      if (this.patientForm.value.id != "") {
        doctor_patient["patient_id"] = this.patientForm.value.id;
        this.DoctorPatientService.submitDoctorPatient(
          doctor_patient
        ).subscribe((res) => {});
      }
    }

    if (this.patientForm.value.id != "") {
      swal({
        type: "success",
        title: "แก้ไขข้อมูลเรียบร้อยแล้ว",
        showConfirmButton: false,
        timer: 1500,
      });
      this.submitted = false;
      this.getPatients();
    } else {
      this.forms_data.doctor_patient = new_doctor_patients;
    }
  }

  submitCarerPatientForm() {
    this.submitted = true;
    if (this.new_carer_patients.length == 0) {
      return false;
    } else {
      this.wizard.navigation.goToNextStep();
    }

    for (let i = 0; i < this.new_carer_patients.length; i++) {
      const element = this.new_carer_patients[i];
      var carer_patient = {
        carer_id: element.carer_id,
        carer_patient_status_id: element.carer_patient_status_id,
        carer_relationship_id: element.carer_relationship_id,
      };

      if (this.patientForm.value.id != "") {
        carer_patient["patient_id"] = this.patientForm.value.id;
        this.CarerPatientService.submitCarerPatient(carer_patient).subscribe(
          (res) => {
            this.new_carer_patients = [];
            this.forms_data.carer_patient = [];
          }
        );
      }
    }
    if (this.patientForm.value.id != "") {
      swal({
        type: "success",
        title: "แก้ไขข้อมูลเรียบร้อยแล้ว",
        showConfirmButton: false,
        timer: 1500,
      });
      this.getPatients();
      this.submitted = false;
    } else {
      this.forms_data.carer_patient = this.new_carer_patients;
    }
  }

  submitPatientMealTimeForm() {
    this.submitted = true;

    if (this.patientMealTimeForm.invalid) {
      return;
    } else {
      this.wizard.navigation.goToNextStep();
    }

    var new_patient_meal_times = [];
    for (let i = 0; i < this.formArray.controls.length; i++) {
      var meal_times = this.formArray.controls[i].value.time;
      // const element = meal_times;
      var obj = {
        meal_time_id: i + 1,
        time: meal_times,
      };
      new_patient_meal_times.push(obj);
    }

    var patient;

    if (this.patientForm.value.id != "") {
      var patient_id = this.patientForm.value.id;
      patient = {
        idcard: this.patientForm.controls.idcard.value,
        first_name: this.patientForm.controls.first_name.value,
        last_name: this.patientForm.controls.last_name.value,
        display_name:
          this.patientForm.controls.first_name.value +
          " " +
          this.patientForm.controls.last_name.value,
        birth_date: this.datepipe.transform(this.birth_date, "yyyy-MM-dd"),
        congenital_disease: this.patientForm.controls.congenital_disease.value,
        new_patient_meal_times: new_patient_meal_times,
      };
      this.patientService
        .submitPatient(patient, patient_id)
        .subscribe((res) => {
          swal({
            type: "success",
            title: "บันทึกข้อมูลเรียบร้อยแล้ว",
            showConfirmButton: false,
            timer: 1500,
          });
          this.getPatients();
          this.setForm();
          this.new_carer_patients = [];
          this.submitted = false;
          this.global.loadScript("assets/script/modal.js");
          this.index_carer_patient = undefined;
        });
    } else {
      var new_doctor_patients = this.forms_data.doctor_patient;
      var new_carer_patients = this.forms_data.carer_patient;

      var patient_params = {
        idcard: this.patientForm.controls.idcard.value,
        first_name: this.patientForm.controls.first_name.value,
        last_name: this.patientForm.controls.last_name.value,
        display_name:
          this.patientForm.controls.first_name.value +
          " " +
          this.patientForm.controls.last_name.value,
        birth_date: this.datepipe.transform(this.birth_date, "yyyy-MM-dd"),
        congenital_disease: this.patientForm.controls.congenital_disease.value,
        new_doctor_patients: new_doctor_patients,
        new_carer_patients: new_carer_patients,
        new_patient_meal_times: new_patient_meal_times,
      };

      this.patientService.submitPatient(patient_params).subscribe((res) => {
        swal({
          type: "success",
          title: "บันทึกข้อมูลเรียบร้อยแล้ว",
          showConfirmButton: false,
          timer: 1500,
        });
        this.getPatients();
        this.setForm();
        this.new_carer_patients = [];
        this.submitted = false;
        this.global.loadScript("assets/script/modal.js");
        this.index_carer_patient = undefined;
      });
    }
  }

  deletePatient(patient_id) {
    swal({
      title: "ยืนยันการลบข้อมูล",
      text: "คุณต้องการลบข้อมูลใช่หรือไม่ ?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#36a3f7",
      cancelButtonColor: "#f4516c",
      confirmButtonText: "ใช่, ลบข้อมูล",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.value) {
        this.patientService.deletePatient(patient_id).subscribe((res) => {
          swal({
            type: "success",
            title: "ลบข้อมูลเรียบร้อยแล้ว",
            showConfirmButton: false,
            timer: 1500,
          });
          this.getPatients();
        });
      }
    });
  }

  editCarerPatient(carer_patient, index) {
    this.index_carer_patient = index;
    this.carerPatientForm.patchValue({
      carer_patients: carer_patient.id
        ? carer_patient.carer_patients
        : carer_patient.carer_patients,
      carer_patient_status_id: carer_patient.carer_patient_status_name,
      carer_relationship_id: carer_patient.carer_relationship_name,
    });
  }

  removeCarerPatient(carer_patien_id, index) {
    this.index_carer_patient = index;
    if (carer_patien_id) {
      swal({
        title: "ยืนยันการลบผู้ดูแล",
        text: "คุณต้องการลบข้อมูลผู้ดูแลใช่หรือไม่ ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#36a3f7",
        cancelButtonColor: "#f4516c",
        confirmButtonText: "ใช่, ลบข้อมูล",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.value) {
          this.patientService
            .deleteCarerPatients(carer_patien_id)
            .subscribe((res) => {
              swal({
                type: "success",
                title: "ลบข้อมูลเรียบร้อยแล้ว",
                showConfirmButton: false,
                timer: 1500,
              });
              this.carer_patients.splice(index, 1);
              this.index_carer_patient = undefined;
              this.getPatients();
              this.new_carer_patients = [];
            });
        }
      });
    } else {
      this.carer_patients.splice(index, 1);
    }
  }

  updateCarerPatientList(element) {
    this.isOpen = false;
    if (this.index_carer_patient != undefined) {
      if (element == "carer_patients") {
        this.carer_patients[
          this.index_carer_patient
        ].carer_patients = this.carerPatientForm.controls.carer_patients.value.display_name;
        this.new_carer_patients[
          this.index_carer_patient
        ].carer_id = this.carerPatientForm.controls.carer_patients.value.id;
      }
      if (element == "carer_relationship_name") {
        this.carer_patients[
          this.index_carer_patient
        ].carer_relationship_name = this.carerPatientForm.controls.carer_relationship_id.value.name;
        this.new_carer_patients[
          this.index_carer_patient
        ].carer_relationship_id = this.carerPatientForm.controls.carer_relationship_id.value.id;
      }
      if (element == "carer_patient_status_name") {
        this.carer_patients[
          this.index_carer_patient
        ].carer_patient_status_name = this.carerPatientForm.controls.carer_patient_status_id.value.name;
        this.new_carer_patients[
          this.index_carer_patient
        ].carer_patient_status_id = this.carerPatientForm.controls.carer_patient_status_id.value.id;
      }
    }
  }

  exportExcel() {
    this.exportloading = true;

    let exportDataTest1 = [];
    let exportDataTest2 = [];

    const filters = {
      order: "created_at asc",
      with_patient_test: true,
    };
    this.patientService
      .getPatients(filters)
      .subscribe((results: Array<any>) => {
        this.exportloading = false;
        results.forEach((data, i) => {
          let infoItem = {
            "NPI APP No.": i + 1,
            Suffix: null,
            "ชื่อ-สกุล ผู้ป่วย": data.display_name,
            ชื่อ: data.first_name,
            นามสกุล: data.last_name,
            Sex_Pt: data.gender_id,
            ผู้ดูแล: data.carer_patients
              ? data.carer_patients.carer_patient_status_name
              : null,
            "ชื่อ-สกุล ผู้ดูแล": data.carer_patients
              ? data.carer_patients.patient_display_name
              : null,
            Sex_Caregiver: data.carer_patients
              ? data.carer_patients.carer.gender_id
              : null,
            ระดับการศึกษา: data.carer_patients
              ? data.carer_patients.carer.level_of_education_name
              : null,
            เบอร์โทร: data.carer_patients
              ? data.carer_patients.carer.phone
              : null,
            อายุ: data.carer_patients ? data.carer_patients.carer.age : null,
            "User Name":
              data.carer_patients && data.carer_patients.carer
                ? data.carer_patients.carer.username
                : null,
            Password: null,
            Birthday: this.datepipe.transform(data.birth_date, "dd/MM/yyyy"),
            Date: new Date(data.birth_date).getDate(),
            Month: new Date(data.birth_date).getMonth() + 1,
            "Year (พ.ศ.)": new Date(data.birth_date).getFullYear() + 543,
            BD_DMY: this.datepipe.transform(
              new Date(
                new Date(data.birth_date).setFullYear(
                  new Date(data.birth_date).getFullYear() + 543
                )
              ),
              "dd/MM/yyyy"
            ),
          };

          let test_1_list = data.patient_tests.filter(
            (test) => test.test_id == 1
          );
          let test_2_list = data.patient_tests.filter(
            (test) => test.test_id == 2
          );

          const keyTest1 = [
            { key: "_APP SCIRS_RepeatDoc" },
            { key: "_APP SCIRS_PtName" },
            { key: "_APP SCIRS_PtName2" },
            { key: "_APP SCIRS_Time" },
            { key: "_APP SCIRS_Place" },
            { key: "_APP SCIRS_Recall" },
            { key: "_APP SCIRS_RecallName" },
            { key: "_APP SCIRS_Season" },
            { key: "_APP SCIRS_Repeat" },
            { key: "_APP SCIRS_AnomiaNose" },
            { key: "_APP SCIRS_AnomiaThumb" },
            { key: "_APP SCIRS_AnomiaColor" },
            { key: "_APP SCIRS_CompreQty" },
            { key: "_APP SCIRS_CompreSize" },
            { key: "_APP SCIRS_CompreCircle" },
            { key: "_APP SCIRS_Clock" },
            { key: "_APP SCIRS_ClockTime" },
            { key: "_APP SCIRS_LtRt" },
            { key: "_APP SCIRS_CommandEyes" },
            { key: "_APP SCIRS_CommandHand" },
          ];

          const keyTest2 = [
            { key: "_APP_Del" },
            { key: "_APP_Hal" },
            { key: "_APP_IrriAgi" },
            { key: "_APP_DepAnx" },
            { key: "_APP_Apa" },
            { key: "_APP_Disinh" },
            { key: "_APP_ABM" },
            { key: "_APP_Eating" },
            { key: "_APP_Sleep" },
          ];

          let test1Item = {};
          test1Item["Age_Pt"] = null;
          if (test_1_list[0]) {
            test1Item["Age_Pt"] = this.global.calculateAge(
              data.birth_date,
              test_1_list[0].created_date
            );
          }

          test_1_list.forEach((test, testIndex) => {
            test1Item["T" + (testIndex + 1) + "_Date"] = test.created_date
              ? new Date(test.created_date).getDate()
              : null;
            test1Item["T" + (testIndex + 1) + "_Month"] = test.created_date
              ? new Date(test.created_date).getMonth() + 1
              : null;
            test1Item[
              "T" + (testIndex + 1) + "_Year (พ.ศ.)"
            ] = test.created_date
              ? new Date(test.created_date).getFullYear() + 543
              : null;
            test1Item["T" + (testIndex + 1) + "_DMY"] = this.datepipe.transform(
              new Date(
                new Date(test.created_date).setFullYear(
                  new Date(test.created_date).getFullYear() + 543
                )
              ),
              "dd/MM/yyyy"
            );
            keyTest1.forEach((kt, ktIndex) => {
              if (test.patient_test_values.length == 20) {
                test1Item["T" + (testIndex + 1) + kt.key] =
                  test.patient_test_values[ktIndex].value;
              } else {
                test1Item["T" + (testIndex + 1) + kt.key] = null;
              }
            });
            test1Item["T" + (testIndex + 1) + "_APP SCIRS_Total"] =
              test.total_score;
          });

          let test2Item = {};
          test2Item["Age_Pt"] = null;
          if (test_2_list[0]) {
            test2Item["Age_Pt"] = this.global.calculateAge(
              data.birth_date,
              test_2_list[0].created_date
            );
          }

          test_2_list.forEach((test, testIndex) => {
            test2Item["T" + (testIndex + 1) + "_Date"] = test.created_date
              ? new Date(test.created_date).getDate()
              : null;
            test2Item["T" + (testIndex + 1) + "_Month"] = test.created_date
              ? new Date(test.created_date).getMonth() + 1
              : null;
            test2Item[
              "T" + (testIndex + 1) + "_Year (พ.ศ.)"
            ] = test.created_date
              ? new Date(test.created_date).getFullYear() + 543
              : null;
            test2Item["T" + (testIndex + 1) + "_DMY"] = this.datepipe.transform(
              new Date(
                new Date(test.created_date).setFullYear(
                  new Date(test.created_date).getFullYear() + 543
                )
              ),
              "dd/MM/yyyy"
            );
            test.total_f = 0;
            test.total_burd = 0;
            keyTest2.forEach((kt, ktIndex) => {
              if (test.patient_test_values.length == 9) {
                if (test.patient_test_values[ktIndex].has_sub_value) {
                  const score =
                    test.patient_test_values[ktIndex].patient_test_sub_values
                      .length === 0
                      ? 0
                      : test.patient_test_values[ktIndex]
                          .patient_test_sub_values[1].value;
                  test2Item["T" + (testIndex + 1) + kt.key] = score;
                  test.total_f += score;
                } else {
                  test2Item["T" + (testIndex + 1) + kt.key] = null;
                }
              } else {
                test2Item["T" + (testIndex + 1) + kt.key] = null;
              }
            });
            keyTest2.forEach((kt, ktIndex) => {
              if (test.patient_test_values.length == 9) {
                if (test.patient_test_values[ktIndex].has_sub_value) {
                  const score =
                    test.patient_test_values[ktIndex].patient_test_sub_values
                      .length === 0
                      ? 0
                      : test.patient_test_values[ktIndex]
                          .patient_test_sub_values[0].value;
                  test2Item["T" + (testIndex + 1) + kt.key + "_Burd"] = score;
                  test.total_burd += score;
                } else {
                  test2Item["T" + (testIndex + 1) + kt.key + "_Burd"] = null;
                }
              } else {
                test2Item["T" + (testIndex + 1) + kt.key + "_Burd"] = null;
              }
            });
            test2Item["T" + (testIndex + 1) + "_Total_F"] = test.total_f;
            test2Item["T" + (testIndex + 1) + "_Total_Burd"] = test.total_burd;
          });

          const dataTest1 = Object.assign({}, infoItem, test1Item);
          const dataTest2 = Object.assign({}, infoItem, test2Item);

          exportDataTest1.push(dataTest1);
          exportDataTest2.push(dataTest2);
        });

        this._excelService.exportAsExcelFile(
          exportDataTest1,
          exportDataTest2,
          "รายงานผลแบบทดสอบ"
        );
      });
  }
}
