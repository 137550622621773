import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { service_url } from "./service-url";

@Injectable({
  providedIn: 'root'
})
export class MedicineService {

  api_url: any
  constructor(private http: HttpClient) {
    this.api_url = service_url.api_url
  }

  getMedicines(filters) {
    let params = new HttpParams();
    Object.keys(filters).forEach(function (key) {
      params = params.append(key, filters[key]);
    });
    return this.http.get(this.api_url + '/medicines', { params: params })
  }

  showMedicine(medicine_id) {
    return this.http.get(this.api_url + '/medicines/' + medicine_id)
  }

  submitMedicine(params, id = '') {
    if (id != '') {
      return this.http.put(this.api_url + '/medicines/' + id, params)
    }
    else {
      return this.http.post(this.api_url + '/medicines', params)
    }
  }

  deleteMedicine(medicine_id) {
    return this.http.delete(this.api_url + '/medicines/' + medicine_id)
  }

  importObjects(params) {
    return this.http.post(this.api_url + '/medicines/import_objects', { medicines: params })
  }






}
