import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { service_url } from "./service-url";

@Injectable({
  providedIn: 'root'
})

export class AuthenService {
  api_url: any
  options = {}

  constructor(private http: HttpClient) {
    this.api_url = service_url.api_url
  }
  authen(obj) {
    return this.http.post(`${this.api_url}/sessions`, obj);
  }
}
