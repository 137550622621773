import { Component, HostListener } from '@angular/core';
import * as $ from "jquery"
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'wearable-admin';
  constructor(
    translate: TranslateService
  ) {
    translate.setDefaultLang("th");
    translate.use("th");
  }

  @HostListener('window:scroll', ['$event']) onWindowScroll($event) {
    if (window.pageYOffset >= 300) {
      $("body").addClass("m-header--minimize-on")
      $("body").removeClass("m-header--minimize-off")


    } else {
      $("body").removeClass("m-header--minimize-on")
      $("body").addClass("m-header--minimize-off")

    }
  }


}