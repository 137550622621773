import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalService } from "../../services/global.service";
import { service_url } from "../../services/service-url";
import { AnnouncementService } from "../../services/announcement.service";
import swal from 'sweetalert2'

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
  announceForm: FormGroup;
  submitted = false;
  api_url = service_url.api_url
  selectedFile: File = null;
  imageUrl: string = './assets/images/placeholder-600x400.png';
  announcements: any;
  hospital_id: any;
  announcement_types: any;
  delImg: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private Global: GlobalService,
    private AnnouncementService: AnnouncementService
  ) { }

  ngOnInit() {
    this.setForm()
    this.getAnnouncements()
    this.getAnnouncementTypes()
    var sessions = JSON.parse(sessionStorage.getItem('sessions'));
    if (sessions.user.role_id == 1) {
      this.hospital_id = null
    }
    else {
      this.hospital_id = sessions.user.doctor.hospital_id
    }
  }

  setForm() {
    this.announceForm = this.formBuilder.group({
      title: ['', Validators.required],
      content: ['', Validators.required],
      announcement_type_id: [1, Validators.required],
      image: null,
    });
  }

  showForm(announce = '') {

    this.submitted = false;
    this.selectedFile = null;
    this.delImg = false
    if (announce != '') {
      this.announceForm = this.formBuilder.group({
        title: [announce['title'], Validators.required],
        content: [announce['body'], Validators.required],
        announcement_type_id: [announce['announcement_type_id'], Validators.required],
        image: null,
        id: announce['id']
      });
      this.imageUrl = announce['imageUrl'];
    }
    else {
      this.setForm()
      this.imageUrl = './assets/images/placeholder-600x400.png';
    }

  }

  handleFileInput(event) {
    this.selectedFile = event.target.files[0]
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    }
    reader.readAsDataURL(this.selectedFile)
  }

  getAnnouncementTypes() {
    this.AnnouncementService.getAnnouncementTypes().subscribe(res => {
      this.announcement_types = res
    })
  }

  getAnnouncements() {
    this.AnnouncementService.getAnnouncements().subscribe(res => {
      this.announcements = res
      this.announcements.forEach(element => {
        if (element.image_url != '') {
          element.imageUrl = this.api_url + element.image_url
        }
        else {
          element.imageUrl = './assets/images/placeholder-600x400.png';
        }

        var date = this.Global.setDateThaiLongDataFormServer(element.created_at)
        var time = this.Global.settimeDataFormServer(element.created_at)
        element.date = date + ' ' + time
      });

    })
  }

  onClickItemDelImg() {
    this.selectedFile = null
    this.imageUrl = './assets/images/placeholder-600x400.png';
    this.delImg = true
  }

  get f() { return this.announceForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.announceForm.invalid) {
      return;
    }
    const fd = new FormData();
    swal({
      title: 'ยืนยันการบันทึก',
      text: "คุณต้องการบันทึกข้อมูลใช่หรือไม่ ?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#36a3f7',
      cancelButtonColor: '#f4516c',
      confirmButtonText: 'ใช่, บันทึกข้อมูล',
      cancelButtonText: 'ยกเลิก'
    }).then((result) => {

      if (result.value) {

        if (this.selectedFile != null) {
          fd.append('announcement[image]', this.selectedFile, this.selectedFile.name)
        }
        else {
          if (this.delImg == true) {
            fd.append('announcement[image]', '')
          }
        }
        fd.append('announcement[title]', this.announceForm.value.title)
        fd.append('announcement[body]', this.announceForm.value.content)
        fd.append('announcement[announcement_type_id]', this.announceForm.value.announcement_type_id)

        if (this.hospital_id != null) {
          fd.append('announcement[hospital_id]', this.hospital_id)
        }
        if (this.announceForm.value.id) {
          var id = this.announceForm.value.id
        }
        // return false
        this.AnnouncementService.submitAnnouncement(fd, id).subscribe(res => {
          if (res['error']) {
            swal({
              type: 'warning',
              title: 'การบันทึกข้อมูลผิดพลาด',
              showConfirmButton: true,
            })
          }
          else {
            swal({
              type: 'success',
              title: 'บันทึกข้อมูลเรียบร้อยแล้ว',
              showConfirmButton: false,
              timer: 1500
            })
            this.getAnnouncements();
            this.setForm()
            this.submitted = false
            this.selectedFile = null
            this.Global.loadScript('assets/script/modal.js');
          }
        })
      }
    })
  }

  delAnnouncement(id) {
    swal({
      title: 'ยืนยันการลบข้อมูล',
      text: "คุณต้องการลบข้อมูลใช่หรือไม่ ?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#36a3f7',
      cancelButtonColor: '#f4516c',
      confirmButtonText: 'ใช่, ลบข้อมูล',
      cancelButtonText: 'ยกเลิก'
    }).then((result) => {
      if (result.value) {
        this.AnnouncementService.deleteAnnouncement(id).subscribe(res => {
          swal({
            type: 'success',
            title: 'ลบข้อมูลเรียบร้อยแล้ว',
            showConfirmButton: false,
            timer: 1500
          })
          this.getAnnouncements();
          this.submitted = false
          this.Global.loadScript('assets/script/modal.js');
        })
      }
    })
  }


}
