import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { ComponentsRoutingModule } from './components-routing.module';
import { LayoutsModule } from '../layouts/layouts.module';
import { ChartsModule } from 'ng2-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { FormsModule } from '@angular/forms';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { DatePipe } from '@angular/common'
// import { NgxMaskInputModule } from 'ngx-mask-input';
import { NgxMaskModule } from 'ngx-mask'
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ClickOutsideModule } from 'ng-click-outside';
import { PapaParseModule } from 'ngx-papaparse';
import { ArchwizardModule } from 'angular-archwizard';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';

import { ComponentsComponent } from './components.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { PatientListComponent } from './patients/patient-list/patient-list.component';
import { PatientComponent } from "./patients/patient/patient.component";
import { PatientDashboardComponent } from "./patients/patient-dashboard/patient-dashboard.component";
import { SectionTest2Component } from './patients/patient-dashboard/section-test2/section-test2.component';
import { PatientTestComponent } from './patients/patient-test/patient-test.component';
import { PatientTest1DetailComponent } from './patients/patient-test1-detail/patient-test1-detail.component';
import { PatientTest2DetailComponent } from './patients/patient-test2-detail/patient-test2-detail.component';
import { PatientScheduleComponent } from './patients/patient-schedule/patient-schedule.component';
import { PatientMedicineComponent } from './patients/patient-medicine/patient-medicine.component';
import { PatientPostsComponent } from './patients/patient-posts/patient-posts.component';
import { NewsComponent } from './news/news.component';
import { ApproveUserComponent } from './approve-user/approve-user.component';
import { SettingHospitalComponent } from './settings/setting-hospital/setting-hospital.component';
import { SettingDoctorComponent } from './settings/setting-doctor/setting-doctor.component';
import { SettingCarerComponent } from './settings/setting-carer/setting-carer.component';
import { SettingMedicineComponent } from './settings/setting-medicine/setting-medicine.component';

import { GlobalService } from "../services/global.service";
import { SettingMedicineGroupComponent } from './settings/setting-medicine/setting-medicine-group/setting-medicine-group.component';
import { SettingMedicineItemComponent } from './settings/setting-medicine/setting-medicine-item/setting-medicine-item.component';
import { SettingMedicineTypeComponent } from './settings/setting-medicine/setting-medicine-type/setting-medicine-type.component';
import { SettingMedicineUnitComponent } from './settings/setting-medicine/setting-medicine-unit/setting-medicine-unit.component';
import { SettingMedicinePackageComponent } from './settings/setting-medicine/setting-medicine-package/setting-medicine-package.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

// export let options: Partial<IConfig> | (() => Partial<IConfig>);

@NgModule({
  declarations: [
    ComponentsComponent,
    ScheduleComponent,
    PatientListComponent,
    PatientComponent,
    PatientDashboardComponent,
    SectionTest2Component,
    PatientTestComponent,
    PatientScheduleComponent,
    PatientMedicineComponent,
    PatientPostsComponent,
    NewsComponent,
    ApproveUserComponent,
    SettingHospitalComponent,
    SettingDoctorComponent,
    SettingCarerComponent,
    SettingMedicineComponent,
    PatientTest1DetailComponent,
    PatientTest2DetailComponent,
    SettingMedicineGroupComponent,
    SettingMedicineItemComponent,
    SettingMedicineTypeComponent,
    SettingMedicineUnitComponent,
    SettingMedicinePackageComponent,
  ],
  imports: [
    CommonModule,
    ComponentsRoutingModule,
    LayoutsModule,
    ReactiveFormsModule,
    NgSelectModule,
    ChartsModule,
    BrowserAnimationsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    FormsModule,
    NgbModalModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserModule,
    DateInputsModule,
    // NgxMaskInputModule,
    NgxMaskModule,
    NoopAnimationsModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    ClickOutsideModule,
    PapaParseModule,
    ArchwizardModule
  ],
  exports: [
    MatTableModule,
    MatSortModule,
    MatPaginatorModule
  ],
  providers: [
    GlobalService,
    DatePipe
  ]
})
export class ComponentsModule { }
